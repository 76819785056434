import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/en/`
  },
  {
    path: '/:lang',
    component: {
      render: (h) => h('router-view'),
    // render (c) {return c('router-view')}
    },
    children: [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:lang/privacypolicy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/:lang/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },
  {
    path: '/:lang/joinrobizaclub',
    name: 'JoinClub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinClub.vue')
  },
  {
    path: '/:lang/ourwines',
    name: 'OurWines',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OurWines.vue')
  },
  {
    path: '/:lang/staking',
    name: 'Staking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Staking.vue')
  },
  {
    path: '/:lang/presalelive',
    name: 'PresaleStarted',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PresaleStarted.vue')
  },
  {
    path: '/:lang/mint',
    name: 'Mint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mint.vue')
  },
  {
    path: '/:lang/admin',
    name: 'Admin',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/user',
    component: () => import('@/views/user/UserLayout.vue'),
    children: [
      {
        path: 'signin',
        name: 'signin',
        component: () => import('@/views/user/Signin.vue'),
      },
      {
        path: 'signout',
        name: 'signout',
        redirect: (to) => {
          return { name: 'signin' }
        }
      }
    ]
  },
]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Auth Middleware
router.beforeEach(async (to, from, next) => {
  let currentUser = null
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const local = localStorage.getItem('user')
  if (local) currentUser = JSON.parse(local)

  if (requiresAuth && !currentUser) {
    localStorage.clear()
    next({ name: 'signin' })
  } else {
    next()
  }
})

export default router
