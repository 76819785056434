<template>
  <div class="asas" id="team">
    <v-container class="cont41">
      <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1tilre5">{{ $t('team.title') }}</h1>
      <div class="laycal">
        <div class="carta-box">
          <div class="carta" data-aos="zoom-in" data-aos-duration="1000">    
            <div class="cara">
              <div class="member-img">
                <section>
                  <img src="@/assets/carmen.png" class="img-fluid" alt="">
                </section>

                <div class="member-info1sola">
                  <h4 class="h4ds">{{ $t('team.name1') }}</h4> 
                  <p class="patrs">{{ $t('team.title1') }}</p>
                </div>
              </div>  
            </div>

            <div class="cara detras">
              <div class="member-info">
                <h4 class="h4dsq23">{{ $t('team.name1') }}</h4> 
                <p class="patrsq23">{{ $t('team.title1') }}</p>
              <v-divider class="diviider2"></v-divider>
                <p class="sp6v">{{ $t('team.content1') }}</p>
              </div>
            </div>    
          </div>
        </div>

        <div class="carta-box">
          <div class="carta" data-aos="zoom-in" data-aos-duration="1000">    
            <div class="cara">
              <div class="member-img">
                <section>
                  <img src="@/assets/gerard.png" class="img-fluid" alt="">
                </section>

                <div class="member-info1">
                  <h4 class="h4ds">{{ $t('team.name2') }}</h4> 
                  <p class="patrs">{{ $t('team.title2') }}</p>
                </div>
              </div>  
            </div>

            <div class="cara detras">
              <div class="member-info">
                <h4 class="h4ds">{{ $t('team.name2') }}</h4> 
                <p class="patrs">{{ $t('team.title2') }}</p>
              <v-divider class="diviider2"></v-divider>
                <p class="sp6w">{{ $t('team.content2') }}
                </p>
              </div>
            </div>    
          </div>
        </div>

        <!-- <div class="carta-box">
          <div class="carta" data-aos="zoom-in" data-aos-duration="1000">    
            <div class="cara">
              <div class="member-img">
                <section>
                  <img src="@/assets/marta.png" class="img-fluid" alt="">
                </section>

                <div class="member-info1">
                  <h4 class="h4ds">{{ $t('team.name3') }}</h4> 
                  <p class="patrs">{{ $t('team.title3') }}</p>
                </div>
              </div>  
            </div>

            <div class="cara detras">
              <div class="member-info">
                <h4 class="h4ds">{{ $t('team.name3') }}</h4> 
                <p class="patrs">{{ $t('team.title3') }}</p>
              <v-divider class="diviider2"></v-divider>
                <p class="sp61">{{ $t('team.content3') }}</p>
              </div>
            </div>    
          </div>
        </div> -->
      </div>

      <div class="laycalqq">
        <div class="carta-box">
          <div class="carta" data-aos="zoom-in" data-aos-duration="1000">    
            <div class="cara">
              <div class="member-img">
                <section>
                  <img src="@/assets/pedro.png" class="img-fluid" alt="">
                </section>

                <div class="member-info1">
                  <h4 class="h4ds">{{ $t('team.name5') }}</h4> 
                  <p class="patrs">{{ $t('team.title5') }}</p>
                </div>
              </div>  
            </div>

            <div class="cara detras">
              <div class="member-info">
                <h4 class="h4ds">{{ $t('team.name5') }}</h4> 
                <p class="patrs">{{ $t('team.title5') }}</p>
              <v-divider class="diviider2"></v-divider>
                <p class="sp61">{{ $t('team.content5') }}</p>
              </div>
            </div>    
          </div>
        </div>

        <div class="carta-box">
          <div class="carta" data-aos="zoom-in" data-aos-duration="1000">    
            <div class="cara">
              <div class="member-img">
                <section>
                  <img src="@/assets/jorge.jpg" class="img-fluid" alt="">
                </section>

                <div class="member-info1">
                  <h4 class="h4ds">{{ $t('team.name6') }}</h4> 
                  <p class="patrs">{{ $t('team.title6') }}</p>
                </div>
              </div>  
            </div>

            <div class="cara detras">
              <div class="member-info">
                <h4 class="h4ds">{{ $t('team.name6') }}</h4> 
                <p class="patrs">{{ $t('team.title6') }}</p>
              <v-divider class="diviider2"></v-divider>
                <p class="sp61">{{ $t('team.content6') }}</p>
              </div>
            </div>    
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>


<script>

export default {
  name: 'Team',
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },
}
</script>


<style>
.qaa1 {
  margin-top: -5px;
}

.patrs {
  color: black;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat",Sans-serif;
}

.patrsq23 {
  color: black;
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat",Sans-serif;
}

.layfootsd {
  position: absolute;
  margin-top: -20px;
  margin-left: 20px;
}

.layfootsd1 {
  margin-top: -10px;
}

.btnfootsd {
  margin-left: 5px;
  margin-right: 5px;
  background: #f3976a;
}

.carta-box {
  width: 250px;
  height: 200px;
  position: relative;
  perspective: 1000px;
  margin: auto;
}

.carta-box:hover .carta {
  transform: rotateY(180deg);
}

.carta {
  transform-style: preserve-3d;
  transition: all 0.8s linear;
}

.cara {
  position: absolute;
  backface-visibility: hidden;
  color: gray;
  width: 250px;
  height: 350px;
}

.cara.detras {
  transform: rotateY(180deg);
  border: solid;
  border-radius: 7px;
  border-width: 1px;
  color: #f3976a;
  width: 250px;
  height: 300px;
  background: #ffffffa6 !important;
}

.asas {
  background-image: url(../assets/mint1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* height: 1500px; */
}

.h1tilre5 {
  margin-bottom: 40px;
  margin-top: 50px;
  font-family: 'Anton', sans-serif;
  font-size: 100px;
  font-weight: 100;
  color: #d4cecc;
  text-align: center;
}

section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

section .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 30px;
  background: url(../assets/wave.png);
  background-size: 150px 30px;
  opacity: 0;
}

section .wave.wave-1 {
  animation: animate 10s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

@keyframes animate
{
  0%
  {
    background-position-x: 0;
  }
    100%
  {
    background-position-x: 150px;
  }
}

section .wave.wave-2 {
  animation: animate2 7s linear infinite;
  z-index: 999;
  opacity: 0.7;
  animation-delay: 0s;
  bottom: 0;
}

@keyframes animate2
{
  0%
  {
    background-position-x: 0;
  }
    100%
  {
    background-position-x: 150px;
  }
}

section .wave.wave-3 {
  animation: animate3 4s linear infinite;
  z-index: 998;
  opacity: 0.5;
  animation-delay: 0s;
  bottom: 0;
}

@keyframes animate3
{
  0%
  {
    background-position-x: 0;
  }
    100%
  {
    background-position-x: 150px;
  }
}

.h4ds {
  color: #5d4539;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Montserrat",Sans-serif;
}

.h4dsq23{
  color: #5d4539;
  font-size: 17px;
  font-weight: 500;
  font-family: "Montserrat",Sans-serif;
}

.diviider2 {
  margin-top: -10px;
  margin-bottom: 0px;
  margin-left: 40px;
  margin-right: 40px;
}

.img-fluid {
  width: 100%;
  border-radius: 7px 7px 7px 7px;
}

.img-fluidqa {
  width: 100%;
  height: 250px;
  border-radius: 7px 7px 7px 7px;
}

.member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}

.member .member-img {
  position: relative;
  overflow: hidden;
}

.member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgb(1 41 112 / 8%);
  transition: 0.3s;
  width: 200px;
  margin: auto;
}

.member:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.memberq:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.memberq {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgb(1 41 112 / 8%);
  transition: 0.3s;
  width: 200px;
  margin: auto;
  margin-bottom: -100px;
}

.member-info {
  text-align: center;
  padding: 15px;
}

.member-info1 {
  text-align: center;
  background: #ffffffa6 !important;
  border-radius: 7px;
  width: 200px;
  margin: auto;
  margin-top: -30px;
  position: relative;
  padding: 3px;
}

.member-info1sola {
  text-align: center;
  background: #ffffffa6 !important;
  border-radius: 7px;
  width: 200px;
  margin: auto;
  margin-top: -45px;
  position: relative;
  padding: 3px;
}

.sp6w {
  font-family: "Montserrat",Sans-serif;
  font-size: 7.5px;
  color: black;
  font-weight: 600;
  text-align: justify;
  padding-top: 10px;
}

.sp6v {
  font-family: "Montserrat",Sans-serif;
  font-size: 7.5px;
  color: black;
  font-weight: 600;
  text-align: justify;
  padding-top: 10px;
}

.sp61 {
  font-family: "Montserrat",Sans-serif;
  font-size: 10px;
  color: black;
  font-weight: 600;
  padding-top: 10px;
  text-align: justify;
}

.laycal {
  margin-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
}

.laycalqq {
  margin-top: 200px;
  margin-bottom: 200px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
 .h1tilre5 {
   font-size: 80px;
 }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .asas {
    height: auto;
  }
  .h1tilre5 {
    font-size: 60px;
  }
  .cara {
    width: 210px;
    height: 310px;
  }
  .cara.detras {
    width: 210px;
    height: 260px;
  }
  .sp61 {
    font-size: 8.5px;
    padding-top: 5px;
  }
  .sp6v {
    font-size: 7px;
  }
  .sp6w {
    font-size: 6.5px;
  }
  .h4ds {
    margin-top: 5px;
  }
}

@media all and (max-width: 600px) {
  .asas {
    height: auto;
  }
  .h4ds {
    font-size: 15px;
  }
  .patrs {
    font-size: 12px;
  }
  .carta-box {
    margin-bottom: 170px;
  }
  .laycalqq {
    align-items: center;
    display: inline;
  }
  .laycal {
    align-items: center;
    display: inline;
  } 
  .h1tilre5 {
    font-size: 40px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .asas {
    background-attachment: initial;
  }
}
</style>
