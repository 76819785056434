<template>
    <div>
      <div class="kof">
        <div class="dfofl">
          <h1 data-aos="zoom-in" data-aos-duration="1500" class="h52fs">{{ $t('help.title') }}</h1>
          <h2 data-aos="zoom-in" data-aos-duration="1500" class="h2vcdl">{{ $t('help.content1') }}</h2>
        </div>
        <div class="hed2s">
            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="gf2s">
              <v-img class="imhelp" src="@/assets/help1.png">
                <div class="dovspa">
                  <span class="asdspa">{{ $t('help.cuad1') }}</span>
                </div>
                <div class="dovspacc">
                  <a class="asasdcc" href="https://ibizapreservation.org/project/posidonia-protection/" target="_blank">{{ $t('help.read') }}</a>
                </div>
              </v-img>
            </div>

            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000" class="adsa4df">
              <v-img class="imhelp" src="@/assets/help2.png">
              <div class="dovspa">
                  <span class="asdspa">{{ $t('help.cuad2') }}</span>
                </div>
                <div class="dovspacc">
                  <a class="asasdcc" href="https://ibizapreservation.org/project/plastic-free-ibiza-and-formentera/" target="_blank">{{ $t('help.read') }}</a>
                </div>
              </v-img>
            </div>
        </div>
      </div>
    </div>
</template>


<style>
.dfofl {
  width: 90%;
  margin: auto;
}

.dovspa {
  text-align: center;
  padding-top: 30%;
  padding-left: 10%;
  padding-right: 10%;
}

.dovspacc {
  text-align: center;
  padding-top: 8%;
}

.asdspa {
  color: aliceblue;
  font-size: 22px;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.asasdcc {
  color: #f3976a !important;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-decoration: none;
}

.asasdcc:hover {
  color: rgb(61, 110, 154) !important;
}

.kof {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.h2vcdl {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 300 !important;
  color: white;
}

.h52fs {
  text-align: center;
  font-size: 50px;
  margin-bottom: 20px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  color: #f3976a;
}

.imhelp {
  width: 70%;
  margin: auto;
  border-radius: 30px;
}

.hed2s {
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: auto;
}

.gf2s {
  width: 50%;
  padding: 20px;
  margin: auto;
}

.adsa4df {
  width: 50%;
  padding: 20px;
  margin: auto;
}

@media all and (min-width: 960px) and (max-width: 1264px) {
 .h52fs {
    font-size: 40px;
  }
  .h2vcdl {
    font-size: 18px;
  }
  .asdspa {
    font-size: 18px;
  }
  .asasdcc {
    font-size: 14px;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
 .h52fs {
    font-size: 30px;
  }
  .h2vcdl {
    font-size: 15px;
  }
  .asdspa {
    font-size: 15px;
  }
  .asasdcc {
    font-size: 12px;
  }
  .hed2s {
    align-items: center;
    display: inline;
  } 
  .gf2s {
    width: 100%;
  }
  .adsa4df {
    width: 100%;
  }
}

@media all and (max-width: 600px) {
  .h52fs {
    font-size: 30px;
  }
  .h2vcdl {
    font-size: 12px;
  }
  .asdspa {
    font-size: 12px;
    letter-spacing: 0;
  }
  .asasdcc {
    font-size: 10px;
    font-weight: 500;
  }
  .kof {
    padding-left: 5px;
    padding-right: 10px;
  }
  .dovspa {
    padding-left: 5%;
    padding-right: 5%;
  }
  .hed2s {
    align-items: center;
    display: inline;
  } 
  .imhelp {
    width: 70%;
  }
  .gf2s {
    width: 100%;
  }
  .adsa4df {
    width: 100%;
  }
}
</style>


<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'HelpPreserve',
  
  mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
}
</script>