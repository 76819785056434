<template>
  <div class=vidsss9>
    <v-container>
      <h1 data-aos="zoom-in" data-aos-duration="1000" class="hprin" align="center" justify="center">{{ $t('benefits.title') }}</h1>

      <div data-aos="zoom-in" data-aos-duration="1000" class="container1">
        <div class="card">
          <div class="icon">
            <ion-icon name="globe-outline"></ion-icon>
          </div>
          <div class="content">
            <h2>{{ $t('benefits.titlecon1') }}</h2>
            <p>{{ $t('benefits.content1') }}</p>
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <ion-icon name="diamond-outline"></ion-icon>
          </div>
          <div class="content">
            <h2>{{ $t('benefits.titlecon2') }}</h2>
            <p>{{ $t('benefits.content2') }}</p>
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <ion-icon name="rocket-outline"></ion-icon>
          </div>
          <div class="content">
            <h2>{{ $t('benefits.titlecon3') }}</h2>
            <p>{{ $t('benefits.content3') }}</p>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>


<script>

export default {
  name: 'Features',
  mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
}
</script>


<style>
.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.card {
  background: #287bff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 270px;
  height: 350px;
  margin: 30px;
  border-radius: 20px;
  border-bottom-left-radius: 160px;
  border-bottom-right-radius: 160px;
  box-shadow: 0 15px 0 #fff,
  inset 0 -15px 0 rgba(255, 255, 255, 0.24),
  0 45px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.card:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.card::before {
    content: "";
    position: absolute;
    top: -140px;
    left: -40%;
    padding: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2));
    transform: rotate(35deg);
    pointer-events: none;
    filter: blur(5px);
}

.card:nth-child(1) {
    background: linear-gradient(to bottom, #ff9f2a, #c25151);
}

.card:nth-child(2) {
    background: linear-gradient(to bottom, #c25151, #ff9f2a);
}

.card:nth-child(3) {
    background: linear-gradient(to bottom, #ff9f2a, #c25151);
}

.card .icon {
  position: relative;
  width: 140px;
  height: 120px;
  background: #f3976a;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 15px 0 rgba(0, 0, 0, 0.1),
         inset 0 -8px 0 #fff;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card .icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-top-right-radius: 50px;
  box-shadow: 15px -15px 0 15px #f3976a;
}

.card .icon::after {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-top-left-radius: 50px;
  box-shadow: -15px -15px 0 15px #f3976a;
}

.card .icon ion-icon {
  color: #fff;
  position: relative;
  font-size: 6em;
  z-index: 1000;
  --ionicon-stroke-width: 24px;
}

.card .content {
  position: absolute;
  width: 100%;
  padding: 30px;
  padding-top: 150px;
  text-align: center;
}

.card .content h2 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 15px;
  font-family: "Montserrat",Sans-serif;
}

.card .content p {
  color: #fff;
  line-height: 1.5em;
  font-size: 15px;
  font-family: "Montserrat",Sans-serif;
}

.laydoc65 {
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
}

.vidsss9 {
  background-image: url(../assets/wineped2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.div53a {
  background: #f3976a;
}

.div5da {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ad {
  position: absolute;
  z-index: 1;
}

.pd58 {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-family: "Montserrat",Sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.h1spd {
  color: #33231d;
  text-align: center;
  font-size: 25px;
  font-family: "Montserrat",Sans-serif;
  padding-bottom: 30px;
  padding-top: 30px;
}

.dif5saaa {
  margin: auto;
}

.card54s {
  height: 250px;
  width: 300px;
  margin: auto;
  border: solid;
  border-color: #b56b16;
  border-radius: 5px 25px 5px 25px;
  border-width: 0 2px 2px 2px !important;
  background: #ffffffa6 !important;
  margin-bottom: 100px;
}

.card54sdis {
  height: 250px;
  width: 300px;
  margin: auto;
  border: solid;
  border-color: #b56b16;
  border-radius: 5px 25px 5px 25px;
  border-width: 0 2px 2px 2px !important;
  background: #ffffffa6 !important;
  margin-bottom: 100px;
}

.hprin {
  text-align: center;
  font-size: 50px;
  margin-bottom: 80px;
  margin-top: 80px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 300;
  color: #f3976a;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
  .pd58 {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
  }

  .hprin {
    font-size: 40px;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .h1spd {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .card54s {
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1.5px 1.5px 1.5px !important;
  }

  .card54sdis {
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1.5px 1.5px 1.5px !important;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .pd58 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
  
  .laydoc65 {
    align-items: center;
    display: inline;
  } 

  .hprin {
    font-size: 35px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .h1spd {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .card54s {
    margin-bottom: 50px;
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1.5px 1.5px 1.5px !important;
  }

  .card54sdis {
    margin-bottom: 50px;
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1.5px 1.5px 1.5px !important;
  }
}

@media all and (max-width: 600px) {
  .laydoc65 {
    align-items: center;
    display: inline;
  } 

  .card54s {
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1px 1px 1px !important;
    margin-bottom: 50px;
  }

  .card54sdis {
    height: 200px;
    width: 250px;
    border-radius: 4px 20px 4px 20px;
    border-width: 0 1px 1px 1px !important;
    margin-bottom: 50px;
  }

  .hprin {
    font-size: 35px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .h1spd {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .pd58 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
}
</style>
