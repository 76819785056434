// Utilities
import { make } from 'vuex-pathify'

// Globals
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { FbAuth, FbDb } from '@/plugins/firebaseTS'
import { collection, doc, getDoc } from 'firebase/firestore'

const state = {
  profile: null,
  notifications: [],
}

const mutations = make.mutations(state)

const actions = {
  signin: async ({ commit }, payload) => {
    try {
      const userCredential = await signInWithEmailAndPassword(FbAuth, payload.email, payload.password)
      const { email, uid } = userCredential.user
      if (email && uid) {
        const user = { email, id: uid }
        commit('profile', user)
        return user
      }

      return false
    } catch (e) {
      console.log(e)
      // return
    }
  },
  signout: async ({ commit }, payload) => {
    try {
      localStorage.clear()
      await signOut(FbAuth)
      return true
    } catch (e) {
      console.log(e)
      // return
    }
  },
  fetch: ({ commit }) => {
    const local = localStorage.getItem('user') || '{}'
    const user = JSON.parse(local)

    for (const key in user) {
      commit(key, user[key])
    }
  },
  update: ({ state }) => {
    localStorage.setItem('user', JSON.stringify(state))
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
