<template>
  <div>
    <div class="imgbanner">
      <div class="layfootads">
        <div class="dif6wqq">
          <div class="distomb">
            <h1 data-aos="zoom-in" data-aos-duration="1000" class="g152s">ROBIZA</h1>
            <h1 data-aos="zoom-in" data-aos-duration="1000" class="g152s">WINE</h1>
            <h1 data-aos="zoom-in" data-aos-duration="1000" class="g152s">TOKEN</h1>
            <!-- <p class="dof54" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">{{ $t('banner.title') }} <br>
              {{ $t('banner.title2') }} <br>
              {{ $t('banner.title3') }}</p> -->
          </div>

          <div class="ol23d">
            <v-btn class="butwq2" rounded :small="$vuetify.breakpoint.smAndDown" :large="$vuetify.breakpoint.lgAndUp" href="/#collections" outlined dark>NFT COLLECTIONS</v-btn>
            <v-btn class="butwq2A" rounded :small="$vuetify.breakpoint.smAndDown" :large="$vuetify.breakpoint.lgAndUp" href="/en/mint" outlined dark>BENEFITS</v-btn>
            <!-- <v-btn class="butwq23" rounded large :small="$vuetify.breakpoint.smAndDown" dark outlined @click="dialog = true" >PLAY -->
              <!-- <v-icon class="dsqdc" :small="$vuetify.breakpoint.smAndDown">mdi-play-circle</v-icon>
            </v-btn> -->
          </div>
        </div>
      </div>
      <div class="prr7d">
        <div class="errfd">
          <h1 class="g152sQ">MINT NOW!</h1>
        </div>
        <!-- <div class='prr7d33'>
           <div><h1 class="g152sqw">{{ days }}</h1></div>
           <div><h1 class="g152sqw">{{ hours }}</h1></div>
           <div><h1 class="g152sqw">{{ minutes }}</h1></div>
           <div><h1 class="g152sqw">{{ seconds }}</h1></div>         
       </div> -->
       <!-- <div class='prr7d33'>
          <div>
            <h1 class="g152sqw">{{ days }}</h1>
            <h1 class="g152sqwa">Days</h1>
          </div>
          <div>
            <h1 class="g152sqw">{{ hours }}</h1>
            <h1 class="g152sqwa">Hours</h1>
          </div>
          <div>
            <h1 class="g152sqw">{{ minutes }}</h1>
            <h1 class="g152sqwa">Mins</h1>
          </div>
          <div>
            <h1 class="g152sqw">{{ seconds }}</h1>
            <h1 class="g152sqwa">Secs</h1>
          </div>              
       </div> -->
        <!-- <div class="prr7d33">
          <div class="g152sqw">{{ days }} : </div>
          <div class="g152sqw">{{ hours }} : </h1></div>
          <div class="g152sqw">{{ minutes }} : </h1></div>
          <div class="g152sqw">{{ seconds }}</h1></div>
        </div> -->
      </div>
    </div>


  
    <div class="text-center">
      <v-dialog v-model="dialog" width="700">
        <v-card class="sdasdv">
          <div>
            <video autoplay controls id="tokenVideo" disablePictureInPicture>
              <source src="@/assets/media/ROBIZATOKENREVISEDFINAL3.mp4" type="video/mp4">
            </video>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Banner',
 
    mounted() {
    AOS.init({
      disable:true

      // disable: function () {
      //   var maxWidth = 800;
      //   return window.innerWidth < maxWidth;
      // }
    });
  },
  
      data () {
      return {
        dialog: false,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    },

    async mounted () {
      this.startTimer();  
    },

    methods: {
      newinstagram: function() {
      window.open("https://www.instagram.com/robizawinenft/");
      },

      newtwitter: function() {
      window.open("");
      },

      joinprivate: function() {
      window.open("https://forms.gle/aM5Rc3eNisSPQEae8");
      },


    startTimer() {
      
      setInterval(() => {
      const now = new Date();
      const end = new Date("2023-05-01T00:00Z"); // Finliza
      const end2 = new Date("2022-01-25T00:00Z");
      // const end3 = new Date("2022-02-09T00:00Z");
      // const end4 = new Date("2022-02-24T00:00Z");
      // const end5 = new Date("2022-03-31T00:00Z"); // Finaliza
      const distance = this.distance;
     
      if(now <= end){
        this.distance = end.getTime() - now.getTime();
        // clearTimeout(timerID);
        // this.getCurrentRate = 0;
      }
      // if (now > end) {
      //   this.distance = end2.getTime() - now.getTime();
      //   this.getCurrentRate = 80000;
      // }
      // if (now >= end2) {
      //   this.distance = end3.getTime() - now.getTime();
      //   this.getCurrentRate = 65000;
      // } 
      // if (now >= end3) {
      //   this.distance = end4.getTime() - now.getTime();
      //   this.getCurrentRate = 50000;
      // }
      // if (now >= end4) {
      //   this.distance = end5.getTime() - now.getTime();
      //   this.getCurrentRate = 40000;
      // }
      // if (now > end5){
      //   this.getCurrentRate = 1;
      //   clearTimeout(timerID);
      // }

      const days = Math.floor(this.distance / 86400000);
      const hours = Math.floor((this.distance % 86400000) / 3600000);
      const minutes = Math.floor((this.distance % 3600000) / 60000);
      const seconds = Math.floor((this.distance % 60000) / 1000);
     
      this.days = days < 10 ? "0" + days : days;
      this.hours = hours < 10 ? "0" + hours : hours;
      this.minutes = minutes < 10 ? "0" + minutes : minutes;
      this.seconds = seconds < 10 ? "0" + seconds : seconds;
      this.divider = 100 / this.value;
      }, 1000);
    }    
  } 
}
</script>


<style>
.div5sssd {
  position: absolute;
}

.imglogo25 {
  width: 200px;
  margin: auto;
}

.dsqdc {
  margin-left: 10%;
}

.sdasdv {
 background: transparent !important;
 }

.icod5aa {
  margin-left: 50px;
  margin-top: 10px;
}

#tokenVideo {
  width: 100%;
}

.g152s {
  font-family: 'Righteous', cursive;
  font-size: 90px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: -25px;
  letter-spacing: 5px;
}

.g152sQ {
  font-family: 'Righteous', cursive;
  font-size: 55px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: -15px;
  letter-spacing: 5px;
}

.g152sqw {
  font-family: 'Righteous', cursive;
  font-size: 40px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: -15px;
  letter-spacing: 2px;
  justify-content: space-between;
  padding: 10px;
}

.errfd {
  text-align: center;
}

.g152sqwa {
  font-family: 'Righteous', cursive;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8) !important;
  /* letter-spacing: 1px; */
  padding: 10px;
}

.prr7d {
  width: 50%;
  margin-top: 50px !important;
  margin: auto;
}

.prr7d33 {
  justify-content: center;
  text-align: center;
  display: flex;
}

.deconstructed {
  position: relative;
  margin: auto;
  height: fit-content;
  color: transparent;
  font-weight: 700;
  line-height: 1.03em;
  font-size: 80px;
  letter-spacing: 3px;
}

.deconstructed > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: floralwhite;
  pointer-events: none;
}

.butwq2 {
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);
  border: none !important;
  margin-left: 100px;
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: 'Montserrat';
}

.butwq2A {
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);
  border: none !important;
  margin-left: 50px;
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: 'Montserrat';
}

.butwq23 {
  margin-top: 20px;
  margin-left: 50px;
  border: none !important;
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: 'Montserrat';
}

.dof54 {
  color: #ffffff;
  font-family: "Montserrat",Sans-serif;
  text-align: center;
  font-size: 20px;
  letter-spacing: 5px;
  margin-top: 20px;
}

.distomb {
  padding-top: 200px;
  margin-bottom: 30px;
  padding-left: 100px;
  box-sizing: border-box;
}

.fpd86 {
 color: #fff;
 font-size: 80px;
 letter-spacing: 3px;
}

.dif6wqq2 {
  width: 50%;
}

.imgbanner {
  background-image: url(../assets/BannerWeb.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 850px;
  margin-top: -140px;
  
}

@media all and (min-width: 960px) and (max-width: 1264px) {
  .imgbanner {
   height: 700px;
  }

  .g152sQ {
    font-size: 45px;
  }

  .prr7d {
    margin-top: 30px !important;
  }

  .ol23d {
    text-align: center;
  }

  .butwq2A {
    margin-left: 50px;
  }

  .butwq2 {
    margin-left: 0px;
  }

  .dof54 {
    font-size: 25px;
  }

  .distomb {
    padding-left: 100px;
    padding-top: 150px;
  }

  .g152s {
    font-size: 80px;
  }

  .g152sqw {
    font-size: 35px;
  }
  
  .g152sqwa {
    font-size: 20px;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .dof54 {
    font-size: 20px;
  }

  .g152sqw {
    font-size: 35px;
  }
  
  .g152sqwa {
    font-size: 20px;
  }
  
  .g152sQ {
    font-size: 45px;
  }

  .ol23d {
    text-align: center;
  }

  .butwq2 {
    margin: auto !important;
  }

  .butwq2A {
    margin-left: 50px;
  }

  .g152s {
    font-size: 60px;
  }

  .distomb {
    padding-top: 150px;
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 25px;
  }

  .imgbanner {
    background-image: url(../assets/BannerWeb.jpg);
    height: 600px;
  }

  .prr7d {
    margin-top: 30px !important;
  }
}

@media all and (max-width: 600px) {
  .ol23d {
    text-align: center;
  }

  .butwq2 {
    margin: auto;
    margin-top: 10px;
  }

  .butwq2A {
    margin: auto;
    margin-left: 20px;
    margin-top: 10px;
  }

  .butwq23 {
    margin-left: 20px;
  }

  .dof54 {
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 400 !important;
    margin-top: 30px;
  }

  .g152s {
    font-size: 50px;
    text-align: center;
    margin-bottom: -20px;
  }


  .distomb {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 15px;
    padding-top: 150px;
    text-align: center;
  }



  .imgbanner {
    background-image: url(../assets/BannerWeb.jpg);
    background-position: 100%;
    height: 500px;
  }

  .g152sQ {
    font-size: 30px;
  }

  .g152sqw {
    font-size: 28px;
    margin-bottom: -25px;
    padding: 10px;
  }

  .g152sqwa {
    font-size: 18px;
  }

  .prr7d {
    width: 70%;
    margin-top: 30px !important;
    margin: auto;
  }
}

@media all and (max-width: 400px) {
  .imgbanner {
    background-position: 82%;
  }

  .g152s {
    font-size: 40px;
    text-align: center;
    margin-top: 5px;
  }

  .butwq2 {
    margin: auto;
    margin-top: 10px;
  }

  .butwq2A {
    margin: auto;
    margin-left: 10px;
    margin-top: 10px;
  }

  .layfootads {
    justify-content: center;
  }
}

</style>
