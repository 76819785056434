<template>
  <div class="div5dda">
      <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1presd" align="center" justify="center">PRIVATE SALE LIVE NOW!</h1>
      <h1 class="h1tea22we" align="center" justify="center">07/07 - 07/09</h1>

      <h1 class="h1tea22" align="center" justify="center">ROBIZA Contract Address</h1>

      <v-layout justify-center class="dcsd">
        <textarea class="textarea11q" readonly="readonly" ref="wallContract">0xe1d1C34909B82BbC9E803a05033AfD2a01e10932</textarea>
        <v-btn x-small text @click="wallContract" class="btn54"><v-icon small redonded>mdi-content-copy</v-icon></v-btn>
      </v-layout>


        <div class="assaq3s22">
          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * days" color="#f3976a">{{ days }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.day') }}</h3>
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 24 ) * hours" color="#f3976a">{{ hours }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.hour') }}</h3>            
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * minutes" color="#f3976a">{{ minutes }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.minute') }}</h3>            
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * seconds" color="#f3976a">{{ seconds }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.second') }}</h3>            
          </div>
        </div>
  
        <div class="buta">
          <v-btn class="" dark small color="#f3976a" @click="joinprivate">JOIN PRIVATE SALE</v-btn>
        </div>
  </div>
</template>


<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'PrivateSale',



  mounted() {

    this.startTimer();

    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },

    data () {
      return {
        size: "",
        days:0,
        hours:0,
        minutes:0,
        seconds:0,
        value: "",
      }
    },

    methods: {
    joinprivate: function() {
      window.open("https://forms.gle/aM5Rc3eNisSPQEae8");
     },

      wallContract() {
        this.$refs.wallContract.select();
        document.execCommand('copy');
      },

    startTimer() {
      
      setInterval(() => {
      const now = new Date();
      const end = new Date("2022-09-07T00:00Z"); // inicio
      // const end2 = new Date("2022-09-05T00:00Z");
      // const end3 = new Date("2022-07-09T00:00Z");
      // const end4 = new Date("2022-07-24T00:00Z");
      // const end5 = new Date("2022-09-07T00:00Z"); // Finliza
      const distance = this.distance;
     
      if(now <= end){
        this.distance = end.getTime() - now.getTime();
        this.getCurrentRate = 0;
      }
    //   if (now > end) {
    //     this.distance = end2.getTime() - now.getTime();
    //     this.getCurrentRate = 80000;
    //  }
    //   if (now >= end2) {
    //     this.distance = end5.getTime() - now.getTime();
    //     this.getCurrentRate = 65000;
    //   } 
      // if (now >= end3) {
      //   this.distance = end4.getTime() - now.getTime();
      //   this.getCurrentRate = 50000;
      // }
      // if (now >= end4) {
      //   this.distance = end5.getTime() - now.getTime();
      //   this.getCurrentRate = 40000;
      // }
      if (now > end){
        this.getCurrentRate = 1;
        clearTimeout(timerID);
      }

      const days = Math.floor(this.distance / 86400000);
      const hours = Math.floor((this.distance % 86400000) / 3600000);
      const minutes = Math.floor((this.distance % 3600000) / 60000);
      const seconds = Math.floor((this.distance % 60000) / 1000);
     
      this.days = days < 10 ? "0" + days : days;
      this.hours = hours < 10 ? "0" + hours : hours;
      this.minutes = minutes < 10 ? "0" + minutes : minutes;
      this.seconds = seconds < 10 ? "0" + seconds : seconds;
      this.divider = 100 / this.value;
      }, 1000);
    }
    },

  computed: {
    size1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 60
        case 'sm': return 80
        case 'md': return 100
        case 'lg': return 120
        case 'xl': return 120
      }
    },

    width1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 2
        case 'sm': return 2
        case 'md': return 3
        case 'lg': return 3
        case 'xl': return 5
      }
    },
  }

}
</script>


<style>
  .btn54 {
    /* margin-left: -25px; */
    color: white !important;
}

.dcsd {
  margin-bottom: 40px;
}

.h1tea22 {
  font-size: 20px;
  margin-bottom: 5px;
  color: white;
  font-family: 'Montserrat', sans-serif;

}

.h1tea22we {
  font-size: 40px;
  margin-bottom: 25px;
  margin-top: -15px;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.textarea11q {
  text-align: center;
  resize: none;
  width: 420px;
  height: 24px;
  outline: none;
  margin-bottom: -7px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  /* color: grey; */
}

.h1presd {
  /* margin-top: 50px; */
  margin-bottom: 20px;
  font-family: 'Anton', sans-serif;
  font-size: 80px;
  font-weight: 100;
  color: rgb(197, 197, 197);
  letter-spacing: 5px;
}

.div5dda {
  /* background: rgb(27, 27, 27); */
  padding-top: 80px;
  padding-bottom: 80px;

  background-image: url(../assets/wall1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

}

.buta{
  text-align: center;
  padding-top: 20px;
}

.assaq3s22 {
  text-align: center;
}

.divprocir22 {
  display: inline-block;
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 10px;
}

.progpur {
  font-size: 50px;
}

.days54 {
  font-size: 20px;
  text-align: center;
  color: aliceblue;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 20px;
}

@media all and (max-width: 1260px) { 
  .divprocir22 {
    margin-left: 30px;
  }
    .days54 {
    font-size: 18px;
  }
  
}

@media all and (max-width: 960px) { 
  .divprocir22 {
    margin-left: 25px;
  }
    .progpur {
    font-size: 45px;
  }
    .days54 {
    font-size: 15px;
  }
    .h1presd {
    font-size: 40px;
  }
    .h1tea22 {
    font-size: 15px;
  }
  
}

@media all and (max-width: 700px) { 
  .divprocir22 {
    margin-left: 5px;
  }
    .progpur {
    font-size: 35px;
  }
}

@media all and (max-width: 600px) { 
  .progpur {
    font-size: 25px;
  }
    .days54 {
    font-size: 11px;
  }
    .h1presd {
    font-size: 30px;
  }
    .textarea11q {
    font-size: 12px;
  }
  .btn54 {
    margin-left: -25px;
    color: white !important;
}
}

@media all and (max-width: 400px) { 
  .divprocir22 {
    margin-left: -3px;
  }

    .progpur {
    font-size: 25px;
  }
    .days54 {
    font-size: 8px;
  }
}



</style>
