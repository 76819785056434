<template>
  <v-app>
    <v-snackbar
        top
        :right="!$vuetify.breakpoint.mobile"
        :width="$vuetify.breakpoint.mobile ? '100%' : undefined"
        :timeout="$root.systemMessage.timeout || 3000"
        v-if="$root.systemMessage && $root.systemMessage.show"
        v-model="$root.systemMessage.show"
        v-bind="$root.systemMessage"
    >
      <v-row>
        <v-col cols="1">
          <v-icon v-if="$root.systemMessage.icon">{{$root.systemMessage.icon}}</v-icon>
        </v-col>
        <v-col
            cols=11
            class="font-weight-bold"
            :class="[$vuetify.breakpoint.mobile ? 'text-center' : 'text-left']"
        >
          {{$root.systemMessage.message}}
        </v-col>
      </v-row>
    </v-snackbar>

    <app-bar></app-bar>
    <Contact/>
  </v-app>
</template>


<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.v-application {
  font-family: 'SnellRoundhand-BoldScript', sans-serif;
  /* background: #ffffffa6 !important; */
  /* background: #001233 !important; */
  /* background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%) !important; */
  background-attachment: fixed !important;

background: linear-gradient(170deg, rgb(249, 131, 72) 17%, rgba(66,23,131,1) 90%) !important; /* Old browsers */
background: -webkit-linear-gradient(180deg, rgba(249, 131, 72) 17%, rgba(66,23,131,1) 90%) !important; /* Chrome10+,Safari5.1+ */
}

.appbar {
  background: #e6d5c6a6 !important;
  
}
</style>


<script>
import AppBar from './components/AppBar.vue';
import Contact from './components/Contact.vue';

export default {
  name: 'App',

  components: { AppBar, Contact },

  data: () => {
    return {

    }
  },
};
</script>
