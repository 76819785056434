<template>
  <v-container id="featu2">
    <v-layout wrap class="fold">
      <v-flex class="lgofd96qwww">
        <div class="lgofd96q"></div>
      </v-flex>

      <v-flex xs12 sm12 md6 class="waa6">
        <h1 class="giktr5">{{ $t('feat2.title') }}</h1><br>
        <p class=" fvcd5">{{ $t('feat2.content1') }}</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
  
<script>
  import Vue from 'vue';
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default Vue.extend({
    name: 'Featu2',
  
    mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
  
    data: () => {
      return {
        data: {}
      }
    },
  })
</script>
  
  
<style>
.fold {
  margin-bottom: 100px;
}

.giktr5 {
  font-size:40px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  color: #f3976a;
  letter-spacing: 3px;
}
  
.waa6 {
  margin: auto;
  width: 50%;
}

.lgofd96q {
  margin: auto;
  /* box-shadow: 10px 10px 3px #666; */
  border-radius: 30px;
  background-image: url("~@/assets/featu2.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* padding-top: 150px; */
  width: 400px;
  height: 300px;
}

.lgofd96qwww {
  margin: auto;
}
  
.fvcd5 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 300;
}
    
@media (max-width: 960px) {
  .giktr5 {
    text-align: center;
  }

  .fvcd5 {
    text-align: center;
    font-size: 15px;
  }

  .waa6 {
    width: 90%;
  }

  .fold {
    flex-direction: column-reverse;
  }
  
  .lgofd96qwww {
    justify-content: center;
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .lgofd96qwww {
    margin-top: 25px;
  }

  .fvcd5{
    font-size: 12px;
  }

  .giktr5 {
    font-size: 20px;
  }

  .lgofd96q {
    width: 300px;
    height: 200px;
  }

  .fold {
      margin-bottom: 50px;
  }
}

@media (max-width: 400px) {
  .lgofd96q {
    width: 280px;
    height: 170px;
  }

  .giktr5 {
    font-size: 17px;
  }
}

@media (max-width: 300px) {
  .lgofd96q {
    width: 260px;
    height: 160px;
  }
}
</style>