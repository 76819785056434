<template>
  <!-- <v-container> -->
  <div id="about">

    <h1 class="h1sc">{{ $t('about.titlpria') }}</h1>
    <p class="dof54" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              {{ $t('banner.title') }} <br>
              {{ $t('banner.title2') }} <br>
              {{ $t('banner.title3') }}</p>

    <div class="divorr555">


      <div class="olefq">
        <v-img class="imgcls" src="@/assets/llave.jpeg"></v-img>
        <div class="rgd">
          <div class="sdaw">
            <h1 class="ddvv">{{ $t('about.titleq1') }}</h1>
          </div>
          <p class="efd">{{ $t('about.contentq1') }}</p>
        </div>
      </div>

      <div class="olef">
        <v-img class="imgcls" src="@/assets/image0.png"></v-img>
        <div class="rgd">
          <div class="sdaw">
            <h1 class="ddvv">{{ $t('about.titleq2') }}</h1>
          </div>
          <p class="efd">{{ $t('about.contentq2') }}</p>
        </div>
      </div>

      <div class="olef">
        <v-img class="imgcls" src="@/assets/sustan.jpeg"></v-img>
        <div class="rgd">
          <div>
            <h1 class="ddvv">{{ $t('about.titleq3') }}</h1>
          </div>
          <p class="efd">{{ $t('about.contentq3') }}</p>
        </div>
      </div>


      <!-- <div class="s2552">
        <v-img class="imgcls" src="@/assets/about.jpeg"></v-img>
        <div class="olfgwww">
          <h1 class="hf4rds">FAUNA DRINKS WINE - THE CLUB</h1>
        </div>
          <p class="aogfb">We aim to provide a solution with our NFT collections. Nowadays it makes difficult to find like-minted people. Being a ROBIZA NFT holder opens the door to a wide range of benefits, a high-end community with similar lifestyle and earning possibilities. The club of luxury, wellness and fruitful business interests 3.0. All in an unparalleled landscape, the white island of Ibiza.</p>
      </div> -->
      
      <!-- <div class="s2552">
        <v-img class="imgcls" src="@/assets/about.jpeg"></v-img>
        <div class="olfgwww">
          <h1 class="hf4rds">ROBIZA WINE</h1>
        </div>
          <p class="aogfb">Unlike other projects, ROBIZA already has a growing and profitable business with a loyal customer base, the wines, that embody nature manifestations, the SUN, SEA and MOON, to now launching our NFT and expanding internationally by involving the community in the journey.</p>
      </div>

      <div class="s2552">
        <v-img class="imgcls" src="@/assets/about.jpeg"></v-img>
        <div class="olfgwww">
          <h1 class="hf4rds">PHILANTROPY & SUSTAINABILITY</h1>
        </div>
          <p class="aogfb">We act in ways to create a sustainable future by doing business the right way. Every collection represents endangered animals of the Pitiusas islands protected in a Robiza Wine bottle. The fauna is affected by plastic solution and the team is committed to offest the environment by partnering with Ibiza Preservation.</p>
      </div> -->


      <!-- <div class="div1sa2"> -->
        <!-- <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1sc">{{ $t('about.titlpria') }}</h1> -->
        <!-- <p data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000" class="kkk"> {{ $t('about.content1') }}
        </p>
        <p data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000" class="kkk">{{ $t('about.content2') }}</p> -->

        <!-- <div class="divspd5">
          <v-btn color="#f3976a" href="/RobizaWineToken_Whitepaper(EN).pdf" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" target="tank" rounded outlined class="dolqqq">WHITEPAPER - English</v-btn>
          <v-btn color="#f3976a" href="/RobizaWineToken_Whitepaper(ES).pdf" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" target="tank"  rounded outlined class="">WHITEPAPER - Spanish</v-btn>
        </div> -->
      <!-- </div> -->

      <!-- <div>
        <div>

        </div>
      </div> -->
    </div>
  </div>
<!-- </v-container> -->
</template>


<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'About',

  data: () => {
    return {
      // items1: [
      //   { title1: 'Whitepaper - English', link: '/RobizaWineToken_Whitepaper(EN).pdf' },
      //   { title1: 'Whitepaper - Español', link: '/RobizaWineToken_Whitepaper(ES).pdf' },
      // ],
    }
  },

  mounted() {
    AOS.init({
      disable:true
      // disable: function () {
      //   var maxWidth = 800;
      //   return window.innerWidth < maxWidth;
      // }
    });
  },
}
</script>


<style>

.dof5433 {
  color: #ffffff;
  /* font-family: 'Righteous', cursive; */
  font-family: "Montserrat",Sans-serif;
  text-align: center;
  /* font-weight: 100 !important; */
  font-size: 20px;
  letter-spacing: 5px;
  margin-top: 20px;
}



#about {
  padding: 0 100px 0 100px;
  
}

.olef {
  /* background: blue; */
  margin: 20px 50px 50px 50px;

  /* width: 300px; */
  /* margin: auto; */
    
}

.olefq {
  /* background: blue; */
  margin: 20px 0px 50px 0px;

  /* width: 300px; */
  /* margin: auto; */
    
}

.rgd {
  text-align: center;
}

.ddvv {
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  color: #f3976a;
  letter-spacing: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: center;

}

.sdaw {
  height: 70px;
}

.efd {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 200;
}

.hf4rds {
  /* background: #f3976a; */
  /* padding: 15px; */
  font-family: "Montserrat",Sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: white;
  /* height: 70px; */
}

.olfgwww {
    height: 100px;
    /* background: #f3976a; */
    justify-content: center;
    display: flex;
    align-items: center;
}

.aogfb {
  /* padding: 15px; */
  font-family: "Montserrat",Sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: white;
}


.dolqqq {
  margin-right: 20px;
}

.df52d {
  background: #ffffffa6 !important;
  color: #f3976a !important;
}

.menu-contented {
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #f3976a !important;
  margin-left: 10px;
}

.divorr555 {
  /* align-items: flex-end; */
  display: flex;
  /* padding-right: 10px; */
  box-sizing: border-box;
  justify-content: center;
  /* background: #f3976a; */
  /* background: #f3976a; */
}

.kkk {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 200;
  margin-top: 10px;
}

.butwq {
  background: #f3976a;
}

.ps54 {
  font-size: 50px;
  font-family: "Montserrat",Sans-serif;
  font-weight: bold;
  color: #f3976a;
  margin-left: 80px;
}

.scapsd2 {
  font-size: 50px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  color: #f3976a;
  letter-spacing: 3px;
}

.h1sc {
  font-family: 'Anton', sans-serif;
  font-size: 80px;
  font-weight: 100;
  opacity: 0.7 !important;
  margin: auto;
  text-align: center;
  margin-top: 50px;
  /* padding-bottom: 20px; */
}

.s2552 {
  width: 50%;
  padding: 50px 30px 30px 30px;
  margin-bottom: auto;
  text-align: center;
  
  /* background: #f3976a; */

}

.imgcls {
  width: 250px;
  height: 250px;
  margin: auto;
  border-radius: 50%;
  /* padding-top: 20px; */
  /* border-radius: 30px; */
  /* box-shadow: 10px 10px 3px #666; */
}

.imgab {
  width: 500px;
}

.div1sa2 {
  width: 60%;
  margin: auto;
  padding-right: 100px;
}

@media all and (min-width: 960px) and (max-width: 1264px) {
  .divorr555 {
    padding-right: 70px;
    display: inline;
  }
  .div1sa2 {
    width: 100%;
  }
  .s2552 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .kkk {
    font-size: 15px;
  }
  .h1sc {
    font-size: 80px;
  }
  .scapsd2 {
    font-size: 25px;
  }
  .ddvv {
    text-align: center;
    margin-bottom: 10px;

  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .divspd5 {
    text-align: center;
  }

  .div1sa2 {
    width: 80%;
  }
  .imgcls {
    width: 250px;
    height: 250px;
  }
  .divorr555 {
    align-items: center;
    display: inline;
  }
  .s2552 {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .h1sc {
    font-size: 60px;
  }
  .kkk {
    font-size: 12px;
  }
  .scapsd2 {
    font-size: 20px;
  }

  .ddvv {
    font-size: 20px;
    margin-bottom: 20px;

  }
}

@media all and (max-width: 600px) {
  .divspd5 {
    text-align: center;
  }

  .imgcls {
    width: 240px;
    height: 240px;
    margin-top: 30px;
    margin-bottom: 20px; 
  }
  .h1sc {
    font-size: 40px;
  }
  .kkk {
    font-size: 12px;
  }
  .div1sa2 {
    width: 90%;
  }
  .divorr555 {
    align-items: center;
    display: inline;
    padding-right: 0;
    
  }
  .s2552 {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 70%;
    padding: 0;
  }
  .scapsd2 {
    font-size: 17px;
  }
  .ddvv {
    font-size: 17px;
    margin-bottom: 30px;
  }
  #about {
    padding: 10px 50px 50px 50px;
  }
  .efd {
    font-size: 15px;
  }
  .olef, .olefq {
    margin: 0px;
  }
}

@media all and (max-width: 400px) {
  .imgcls {
    width: 240px;
    height: 240px;
  }

  .olef, .olefq {
    margin: 0px;
  }
}

@media all and (max-width: 300px) {
  .imgcls {
    width: 200px;
    height: 200px;
  }
}
</style>
