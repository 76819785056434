<template>
  <div class="divodsf">
    <v-container class="contpresale">
      <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1pre" align="center" justify="center">{{ $t('presale.title') }}</h1>
      <h2 data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="h2pre" align="center" justify="center">{{ $t('presale.content1') }}</h2>
      <h2 data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000" class="h2pre" align="center" justify="center">{{ $t('presale.content2') }}</h2>
      <h2 data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="h2pre" align="center" justify="center">{{ $t('presale.content3') }}</h2>

      <div class="containerq" data-aos="zoom-in" data-aos-duration="1000">
        <div class="cardq">
          <div class="boxq">
            <div class="contentq">
              <h2>01</h2>
              <h3>{{ $t('presale.phase1') }}</h3>
              <h6 class="h3cart" align="center" justify="center">{{ $t('presale.time1') }}</h6>
              <h5 class="h4cartpri" align="center" justify="center">{{ $t('presale.price') }}</h5>
              <h4 class="h4carts" align="center" justify="center">0.0018€/Token</h4>
              <h5 class="h4cart" align="center" justify="center">{{ $t('presale.mincont') }}</h5>
              <h4 class="h4carts" align="center" justify="center">500€</h4>
              <h5 class="h4cart" align="center" justify="center">Available</h5>
              <h4 class="h4cartsa" align="center" justify="center">40,000,000</h4>
            </div>
          </div>
        </div>
        <div class="cardq">
          <div class="boxq">
            <div class="contentq">
              <h2>02</h2>
              <h3>{{ $t('presale.phase2') }}</h3>
              <h6 class="h3cart" align="center" justify="center">{{ $t('presale.time2') }}</h6>
              <h5 class="h4cartpri" align="center" justify="center">{{ $t('presale.price') }}</h5>
              <h4 class="h4carts" align="center" justify="center">0.0020€/Token</h4>
              <h5 class="h4cart" align="center" justify="center">{{ $t('presale.mincont') }}</h5>
              <h4 class="h4carts" align="center" justify="center">300€</h4>
              <h5 class="h4cart" align="center" justify="center">Available</h5>
              <h4 class="h4cartsa" align="center" justify="center">80,000,000</h4>
            </div>
          </div>
        </div>
        <div class="cardq">
          <div class="boxq">
            <div class="contentq">
              <h2>03</h2>
              <h3>{{ $t('presale.phase3') }}</h3>
              <h6 class="h3cart" align="center" justify="center">{{ $t('presale.time3') }}</h6>
              <h5 class="h4cartpri" align="center" justify="center">{{ $t('presale.price') }}</h5>
              <h4 class="h4carts" align="center" justify="center">0.0025€/Token</h4>
              <h5 class="h4cart" align="center" justify="center">{{ $t('presale.mincont') }}</h5>
              <h4 class="h4carts" align="center" justify="center">100€</h4>
              <h5 class="h4cart" align="center" justify="center">Available</h5>
              <h4 class="h4cartsa" align="center" justify="center">130,000,000</h4>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>


<script>
import Vue from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default Vue.extend({
  name: 'Presale',

  mounted() {
      
    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },

  data: () => {
    return {
      data: {}
    }
  },
})
</script>


<style>
.containerq{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}
.containerq .cardq{
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(243, 151, 106,0.2),
              inset -5px -5px 15px rgba(243,151,106,0.1),
              5px 5px 15px rgba(243, 151, 106,0.3),
              -5px -5px 15px rgba(243,151,106,0.1);
  border-radius: 15px;
  margin: 30px;
}
.containerq .cardq .boxq {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(to bottom, #f9af7a, #c75b5b);
  border-radius: 15px;
  box-shadow: 0 20px 50px rgba(117, 116, 116, 0.5);
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.containerq .cardq .boxq::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255,255,255,0.05);
  pointer-events: none;
}
.containerq .cardq .boxq:hover{
  transform: translateY(-50px);
  box-shadow: 0 40px 70px rgba(0,0,0,0.5);
}
.containerq .cardq .boxq .contentq{
  padding: 20px;
  text-align: center;
}
.containerq .cardq .boxq .contentq h2{
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(255,255,255,0.2);
}
.containerq .cardq .boxq .contentq h3{
  font-size: 40px;
  color: rgba(255,255,255,0.7);
  z-index: 1;
  transition: 0.5s;
}
.containerq .cardq .boxq .contentq h4{
  font-size: 15px;
  color: rgba(255,255,255,0.7);
  z-index: 1;
  transition: 0.5s;
}
.containerq .cardq .boxq .contentq h5{
  font-size: 21px;
  color: rgba(255,255,255,0.7);
  z-index: 1;
  transition: 0.5s;
}
.containerq .cardq .boxq .contentq h6{
  font-size: 25px;
  color: rgba(255,255,255,0.7);
  z-index: 1;
  transition: 0.5s;
}
.containerq .cardq .boxq .contentq p{
  font-size: 1em;
  font-weight: 300;
  color: rgba(255,255,255,0.7);
  z-index: 1;
  transition: 0.5s;
}

.sdc {
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.divtecart {
  width: fit-content;
  margin: auto;
  margin-top: -15px;
}

.h1cart {
  font-size: 35px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 500;
  padding-bottom: 12px;
}

.h3cart {
  padding-top: 10px;
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
}

.h4cart {
  padding-top: 10px;
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
  
}

.h4cartpri {
  padding-top: 20px;
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
}

.h4carts {
  font-weight: 400;
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
  padding-bottom: 12px;
}

.h4cartsa {
  font-weight: 400;
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
}

.itemsub223q {
  margin-top: 10px;
  font-size: 14px;
  padding-left: 20px;
  width: fit-content;
}

.itemsub223q3 {
  margin-top: 10px;
  font-size: 14px;
  padding-left: 17px; /* aviable token  200.000.000*/
  width: fit-content;
}

.headsline {
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px;
}

.lay2a {
  padding-top: 10px;
}

.contpresale {
  padding-top: 60px;

}

.itemsub {
  font-size: 16px;
}

.itemsubq {
  font-size: 16px;
  margin-top: 10px;
}

.itemsub1 {
  margin-top: 30px;
  font-size: 14px;
  padding-left: 45px;
}

.itemsub122 {
  margin-top: 30px;
  font-size: 14px;
  padding-left: 82px;
  width: fit-content;
}

.itemsub2 {
  margin-top: 10px;
  font-size: 14px;
  padding-left: 45px;
  width: fit-content;
}

.itemsub223 {
  margin-top: 10px;
  font-size: 14px;
  padding-left: 25px; /* aviable token  50.000.000*/
  width: fit-content;
}

.layfuer {
  padding-left: 5%;
  padding-right: 5%;
}

.grow {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0s linear;
  width: 350px !important;
  margin: auto;
  border: solid;
  border-color: #b56b16;
  border-radius: 4px 25px 4px 25px !important;
  border-width: 2px 2px 2px 2px !important;
}

.grow:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: all 0.3s ease-in-out;
}

.grow1 {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0s linear;
  width: 350px;
  margin: auto;
  border: solid;
  border-radius: 4px 25px 4px 25px !important;
  background: linear-gradient(20deg, rgba(167,154,149,1) 0%, rgba(252,174,136,1) 100%);
}

.grow1:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: all 0.3s ease-in-out;
}

.grow2 {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0s linear;
  width: 350px;
  margin: auto;
  border: solid;
  border-color: #b56b16;
  border-radius: 4px 25px 4px 25px !important;
  background: linear-gradient(20deg, rgba(167,154,149,1) 0%, rgba(252,174,136,1) 100%);
}

.grow2:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: all 0.3s ease-in-out;
}

.grow3 {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0s linear;
  margin: auto;
  border: solid;
  border-color: #b56b16;
  border-radius: 4px 25px 4px 25px;
  border-width: 2px 2px 2px 2px !important;
}

.grow3:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: all 0.3s ease-in-out;
}

.h1pre {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: 'Anton', sans-serif;
  font-size: 80px;
  font-weight: 100;
  color: gray;
  letter-spacing: 5px;
}

.h2pre {
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.fondo {
	background: linear-gradient(to right, #7deffa 0%, #a0aee9 100%);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
  width: auto;
  height: auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (max-width: 1264px) {
  .grow {
    margin: auto;
  }
}

@media (max-width: 960px) {
  .h1pre {
    font-size: 40px;
  }
  .h2pre {
    font-size: 20px;
  }
  .sdc {
    display: inline;
  }
}

@media (max-width: 600px) {
  .h1pre {
    font-size: 30px;
  }

  .h2pre {
    font-size: 15px;
  }

  .sdc {
    display: inline;
  }
}

@media (max-width: 390px) {
  .sdc {
    display: inline;
  }

  .itemsub122 {
    padding-left: 72px;
    font-size: 12px;
  }

  .itemsub223 {
    font-size: 12px;
    padding-left: 10px;
  }

  .itemsub223q {
    padding-left: 6px;
    font-size: 12px;
  }

  .itemsub223q3 {
    padding-left: 2px;
    font-size: 12px;
  }

  .itemsub2 {
    font-size: 12px;
    padding-left: 35px;
  }

  .itemsub1 {
    font-size: 12px;
    padding-left: 35px;
  }
}
</style>