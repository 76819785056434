<template>
  <div class="tokenomics" id="tokenomics"> 
    <v-container>
      <div>
        <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1tepart1">{{ $t('tokenomics.title') }}</h1>
        <div class="divdiv">
          <div class="senddiv"            >
            <v-flex class="divdis" ref="chartdiv" data-aos="fade-zoom-in" data-aos-duration="1500"></v-flex>
            <v-img class="asdassdasd" src="@/assets/white.png"></v-img>
            <div class="psdic9">

              <v-layout>
                <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="divos93">Token Name: Robiza Wine Token</div>
                <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="divos93">Symbol: $ROBIZA</div>
              </v-layout>

              <div>
                <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="divos93">Total Supply: 1.000.000.000</div>
              </div>

            </div>
          </div>

          <div class="asdasdccc">
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">25%</div>
              <div class="btn5s41">Public Sale</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">20%</div>
              <div class="btn5s43">PR & Marketing</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">20%</div>
              <div class="btn5s44">Liquidity</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">10%</div>
              <div class="btn5s45">Private Sale</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">10%</div>
              <div class="btn5s46">Staking</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">10%</div>
              <div class="btn5s46">Team</div>
            </v-layout>
            <v-layout data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
              <div class="ds">5%</div>
              <div class="btn5s47">Airdrops</div>
            </v-layout>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: 'Tokenomics',
  components: {
  },

  mounted() {

  let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  
  chart.data = [
    {
      distribution: "Public Sale",
      percent: 25,
    },
    {
      distribution: "PR & Marketing",
      percent: 20
    },
    {
      distribution: "Liquidity",
      percent: 20
    },
    {
      distribution: "Private Sale",
      percent: 10
    },
    {
      distribution: "Staking",
      percent: 10
    },
    {
      distribution: "Team",
      percent: 10
    },
        {
      distribution: "Airdrops",
      percent: 5
    },
  ];

  let series = chart.series.push(new am4charts.PieSeries());
  series.dataFields.value = "percent";
  series.dataFields.category = "distribution";
  series.fontSize = 11.5;
  series.fontWeight = 100;
  // series.stroke = am4core.color("#f0f8ff");
  series.stroke = am4core.color("#f3976a"); // red
  // series.strokeWidth = 1; // 3px

this.chart = chart;

    series.colors.list = [
      am4core.color("#de8b63"),/*liquidity*/
      am4core.color("#b47555"),/*plataforma*/
      am4core.color("#9a674c"),/*marketing*/
      am4core.color("#835a45"),/*staking*/
      am4core.color("#6a4c3d"),/*team*/
      am4core.color("#5d4539"),/*sale*/
      am4core.color("#4f3d34"),/*burn*/
    ];
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
</script>


<style>
.divdiv {
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
}

.asdassdasd {
  position: absolute;
  margin-top: -20px;
  height: 40px;
  width: 100px;
}

.tokenomics {
  background: #fff !important;
}

.psdic9 {
  margin-top: 20px;
}

.divos93 {
  border: solid;
  border-color: #4f3d34;
  border-width: 1px;
  border-radius: 5px 25px 5px 25px;
  width: 50%;
  height: fit-content;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
  background: #f3976a;
  color: aliceblue;
}

.ds {
  width: fit-content;
  color: #f3976a;
  font-size: 20px;
}

.btn5s41 {
  border: solid;
  border-color: #5d4539;
  border-width: 1px;
  border-radius: 5px 30px 5px 30px;
  width: 80%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #de8b63;
  color: aliceblue;
  font-size: 16px;
}

.btn5s42 {
  border: solid;
  border-color: #6a4c3d;
  border-width: 1px;
  border-radius: 5px 30px 5px 30px;
  width: 80%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #b47555;
  color: aliceblue;
}

.btn5s43 {
  border: solid;
  border-color: #835a45;
  border-width: 1px;
  border-radius: 5px 30px 5px 30px;
  width: 70%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #9a674c;
  color: aliceblue;
}

.btn5s44 {
  border: solid;
  border-color: #9a674c;
  border-width: 1px;
  border-radius: 5px 25px 5px 25px;
  width: 60%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #835a45;
  color: aliceblue;
}

.btn5s45 {
  border: solid;
  border-color: #b47555;
  border-width: 1px;
  border-radius: 5px 25px 5px 25px;
  width: 50%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #6a4c3d;
  color: aliceblue;
}

.btn5s46 {
  border: solid;
  border-color: #de8b63;
  border-width: 1px;
  border-radius: 5px 25px 5px 25px;
  width: 40%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 10px;
  margin-bottom: 30px;
  background: #5d4539;
  color: aliceblue;
}

.btn5s47 {
  border: solid;
  border-color: #f3976a;
  border-width: 1px;
  border-radius: 5px 25px 5px 25px;
  width: 30%;
  height: fit-content;
  padding-left: 15px;
  margin-left: 20px;
  background: #4f3d34;
  color: aliceblue;
}

.asdasdccc {
  width: 50%;
  padding-left: 150px;
  margin: auto;
  padding-bottom: 50px;
}

.senddiv {
  margin-bottom: 50px;
  width: 50%;
}

.divdis {
  height: 330px;
  margin: auto;
}

.h1tepart1 {
  text-align: end;
  font-size: 100px;
  font-weight: 100;
  opacity: 0.7 !important;
  margin-bottom: 40px;
  margin-top: 50px;
  font-family: 'Anton', sans-serif;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
  .h1tepart1 {
    font-size: 80px;
  }
  .divdis {
    height: 250px;
  }
  .divos93 {
    font-size: 14px;
  }
  .asdassdasd {
    margin-top: -13px;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .h1tepart1 {
    font-size: 60px;
  }
  .senddiv {
    width: 100%;
    margin: auto;
  }
  .asdasdccc {
    width: 100%;
    padding-left: 80px; 
    padding-right: 80px; 
  }
  .divdiv {
    align-items: center;
    display: inline;
  } 
}

@media all and (max-width: 600px) {
  .h1tepart1 {
    font-size: 40px;
    margin-right: 10px;
  }
  .senddiv {
    width: 100%;
  }
  .asdasdccc {
    width: 100%;
    padding-left: 40px; 
    padding-right: 40px; 
  }
  .divdis {
    height: 150px;
  }
  .ds {
    font-size: 17px;
  }
  .btn5s41, .btn5s42, .btn5s43, .btn5s44, .btn5s45, .btn5s46, .btn5s47 {
    font-size: 14px;
  }
  .divos93 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .divdiv {
    align-items: center;
    display: inline;
  } 
  .asdassdasd {
    margin-top: -20px;
    height: 30px;
    width: 35px;
  }
}
</style>
