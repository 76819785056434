<template>
  <v-container id="featu2">
    <v-layout wrap class="fold3">
      <v-flex xs12 sm12 md6 class="mbvc3">
        <h1 class="golf8">{{ $t('feat3.title') }}</h1><br>
        <p class=" peodl54">{{ $t('feat3.content1') }}</p>
        <p class=" peodl54">{{ $t('feat3.content2') }}</p>
        <p class=" peodl54">{{ $t('feat3.content3') }}</p>
      </v-flex>

      <v-flex class="gvvm3d">
          <div class="gvvm3"></div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
  
<script>
  import Vue from 'vue';
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default Vue.extend({
    name: 'Featu3',
  
    mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
  
    data: () => {
      return {
        data: {}
      }
    },
  })
</script>
  
  
<style>
.fold3 {
  margin-bottom: 100px;
}

.golf8 {
  font-size: 40px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  color: #f3976a;
  letter-spacing: 3px;
}
  
.mbvc3 {
  margin: auto;
  width: 50%;
}

.gvvm3 {
  margin: auto;
  /* box-shadow: 10px 10px 3px #666; */
  border-radius: 30px;
  background-image: url("~@/assets/featu3.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* padding-top: 150px; */
  width: 400px;
  height: 300px;
}

.gvvm3d {
  margin: auto;
}

.peodl54 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 300;
}

@media (max-width: 960px) {
  .golf8 {
    text-align: center;
  }

  .peodl54 {
    text-align: center;
    font-size: 15px;
  }

  .gvvm3d {
    justify-content: center;
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .gvvm3d {
    margin-top: 25px;
  }

  .peodl54{
    font-size: 12px;
  }

  .golf8 {
    font-size: 20px;
  }

  .gvvm3 {
    width: 300px;
    height: 200px;
  }
}

@media (max-width: 400px) {
  .gvvm3 {
    width: 280px;
    height: 170px;
  }

  .golf8 {
    font-size: 17px;
  }
}

@media (max-width: 300px) {
  .gvvm3 {
    width: 260px;
    height: 160px;
  }
}
</style>