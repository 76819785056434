<template>
  <div>
    <Banner />
    <!-- <PrivateSale />
    <Presale /> -->
    <NFTCollections />
    <About />
    <!-- <Club /> -->
    <!-- <NFTCollections /> -->
    <Featu1 />
    <Featu2 />
    <Featu3 />
    <!-- <Features /> -->
    <Roadmap />
    <!-- <Tokenomics /> -->
    <Team />
    <Partners />
    <HelpPreserve />
    <Faq />
  </div>
</template>


<script>
import Banner from '@/views/Banner.vue'
import About from '@/views/About.vue'
import Featu1 from '@/views/Featu1.vue'
import Featu2 from '@/views/Featu2.vue'
import Featu3 from '@/views/Featu3.vue'
import Features from '@/views/Features.vue'
import Club from '@/views/Club.vue'
import Roadmap from '@/views/Roadmap.vue'
import Tokenomics from '@/views/Tokenomics.vue'
import Partners from '@/views/Partners.vue'
import Team from '@/views/Team.vue'
import Contact from '@/components/Contact.vue'
import HelpPreserve from './HelpPreserve.vue'
import Faq from '@/views/Faq.vue'
import Presale from './Presale.vue'
import PrivateSale from '@/views/PrivateSale.vue'
import NFTCollections from './NFTCollections.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    About,
    Featu1,
    Featu2,
    Featu3,
    Features,
    Club,
    Roadmap,
    Tokenomics,
    Partners,
    Team,
    Contact,
    HelpPreserve,
    Faq,
    Presale,
    PrivateSale,
    NFTCollections
  }
}
</script>
