import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import firebase from 'firebase/compat/app'
import "firebase/compat/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDUi0zEE3518K8WoFXP0jYEdLRaOHdEIRg",
  authDomain: "robiza-wine-token.firebaseapp.com",
  projectId: "robiza-wine-token",
  storageBucket: "robiza-wine-token.appspot.com",
  messagingSenderId: "433956062833",
  appId: "1:433956062833:web:f6d4f348cbe7a80b77bdd0",
  measurementId: "G-SL869NMBW5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  data () {
    return {
      loading: false,
      systemMessage: {},
    }
  },
  methods: {
    toggleLoading () {
      this.loading = !this.loading;
    },
    setSystemMessage(props) {
      props.show = true;
      this.systemMessage = props;
    }
  },
  render: h => h(App)
}).$mount('#app')
