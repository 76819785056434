<template>
    <div id="collections" class="sada">
        <div class="prinqw">
            <h1 class="h1fw">Robiza NFT Collections</h1>
        </div>
        <div class="divcar">
            <v-card class="divend" >
                    <v-img class="imf4" src="@/assets/animationsea.gif"></v-img>
                <h2 class="h2f"><span>SEA</span> Collection</h2>
                <!-- <p class="p1f">{{ $t('nftmember.price') }}: 0.1 ETH</p>
                <p class="wdes">{{ $t('nftmember.collection') }} 1,200</p> -->
                <v-card-actions class="justify-center ollr">
                    <v-btn href="https://app.robizawinetoken.com/seacollection" block color="white" outlined rounded >
                        {{ $t('nftmember.more') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="divends" >
                    <v-img class="imf4" src="@/assets/animationsun.gif"></v-img>
                <h2 class="h2f"><span>SUN</span> Collection</h2>
                <!-- <p class="p1f">{{ $t('nftmember.price') }}: 0.2 ETH</p>
                <p class="wdes">{{ $t('nftmember.collection') }} 1,000</p> -->
                <v-card-actions class="justify-center ollr">
                    <v-btn href="https://app.robizawinetoken.com/suncollection" block color="white" outlined rounded >
                        {{ $t('nftmember.more') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="divend1">
                    <v-img class="imf4" src="@/assets/animationmoon.gif"></v-img>
                <h2 class="h2f"><span>MOON</span> Collection</h2>
                <!-- <p class="p1f">{{ $t('nftmember.price') }}: 0.3 ETH</p>
                <p class="wdes">{{ $t('nftmember.collection') }} 800</p> -->
                <v-card-actions class="justify-center ollr">
                    <v-btn href="https://app.robizawinetoken.com/mooncollection" block color="white" outlined rounded >
                        {{ $t('nftmember.more') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>


<style>
.ollr {
    margin-left: 15px;
    margin-right: 15px;
}

.wdes {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: #f1c5b0;
}

.p12f {
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    color: #f1c5b0;
}

.p12ff {
    font-size: 18px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    color: #f1c5b0;
    font-weight: bold;
    margin-top: 30px;
}

.sada {
    padding-bottom: 60px;
    padding-top: 60px;
    /* background-image: url(../assets/Mint773.jpg); */
    background-image: url(../assets/mint1.jpg);
    /* background: #001233; */
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-attachment: fixed; */
}

.h1fw {
  font-size: 50px;
  /* font-family: "Montserrat",Sans-serif; */
  font-family: 'Righteous', cursive;

  font-weight: 300;
  color: #f3976a;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}

.ppfw {
  font-size: 20px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 200;
  color: #f1c5b0;
  letter-spacing: 4px;
  margin-bottom: 50px;
  padding-left: 200px;
  padding-right: 200px;

}

.h2f {
    text-align: center;
    margin-bottom: 15px;
    font-size: 22px;
    color: white;
    font-weight: 300;
    font-family: "Montserrat",Sans-serif;
}

.h2f span {
    font-weight: bold;
}

.p1f {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: #f1c5b0;
}

.imf4 {
    width: 220px;
    height: 220px;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.prinqw {
    text-align: center;
    margin-bottom: 30px;
}

.divcar{
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    flex-wrap: wrap;
}

.divend {
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 300px;
    padding-bottom: 20px;
    /* background: rgba(255, 166, 124, 0.4) !important; */
    background-image: linear-gradient(160deg, #5192d9 0%, #e3b065 100%);
    border-radius: 30px !important;
    margin-bottom: 60px;

}

.divends {
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 300px;
    /* padding-bottom: 30px; */
    /* background: rgba(255, 166, 124, 0.4) !important; */
    background-image: linear-gradient(160deg, #e3b065 0%, #9947aa 100%);
    border-radius: 30px !important;
    margin-bottom: 60px;

}

.divend1 {
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 300px;
    /* padding-bottom: 30px; */
    /* background: rgba(255, 166, 124, 0.4) !important; */
    background-image: linear-gradient(160deg, #9947aa 0%, #5192d9 100%);
    border-radius: 30px !important;
    /* margin-top: 80px; */
    margin-bottom: 60px;

}


@media all and (min-width: 600px) and (max-width: 960px) {
    .h2f {
        font-size: 170px;
    }

    .divends, .divend {
        width: 260px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 60px;
    }

    .divend1 {
        /* margin-top: 80px; */
        width: 260px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .h2f {
        font-size: 16px;
    }

    .p1f {
        font-size: 16px;
    }

    .p12f {
        font-size: 14px;
    }

    .imf4 {
        width: 190px;
        height: 190px;
    }
    .h1fw {
        font-size: 35px;
    }
}

@media all and (max-width: 600px) {
    .h1fw {
        font-size: 30px;
    }
    .imf4 {
        width: 185px;
        height: 185px;
    }

    .divend1, .divends {
        width: 250px;
        /* margin-top: 50px; */
    }

    .divend {
        width: 250px;
    }

    .h2f {
        font-size: 17px;
    }

    .p1f, .wdes {
        font-size: 16px;
    }
}
</style>


<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'NFTCollections',
  mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
}
</script>