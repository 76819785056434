<template>
  <div class="divw964">
    <v-container>
      <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1tepart" align="center" justify="center">{{ $t('partners.title') }}</h1>
      
      <div class="flexfaq">
        <p class=" h1faq" data-aos="zoom-in" data-aos-duration="1000">{{ $t('partners.content') }}</p><br>
      </div>

      <v-layout class="laypi">
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/luxry.jpg"></v-img></div>  
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/identify.jpg"></v-img></div>          
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/drink.jpg"></v-img></div>  
      </v-layout>

      <v-layout class="laypi">
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/meta.jpg"></v-img></div>      
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/coinmarketcap.png"></v-img></div>      
        <!-- <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/binan.jpg"></v-img></div>    -->
      </v-layout>
      
      <v-layout class="laypi1">
        <!-- <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/pancka.jpg"></v-img></div>       -->
        <div data-aos="zoom-in" data-aos-duration="1000" class="divlay1"><v-img class="imgpart" src="@/assets/partners/Niall.jpg"></v-img></div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Partners',
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },
}
</script>


<style>


.divw964 {
  background: rgb(35, 16, 68) !important;
  /* margin-bottom: 100px; */
  padding: 25px 0 50px 0;
}

.divlay1 {
  width: 250px;
  height: fit-content;
  /* padding-bottom: 30px; */
  padding: 20px;
  border: solid;
  border-radius: 30px;
  border-width: 2px;
  margin: auto;
  background: aliceblue;
  place-items: center !important;
}

.imgpart {
  width: 80%;
  margin: auto;
}

.laypi {
  justify-content: center;
  margin: 20px;
}

.laypi1 {
  justify-content: center;
}

.h1tepart {
  margin-bottom: 20px;
  font-family: 'Anton', sans-serif;
  font-size: 100px;
  font-weight: 100;
  opacity: 0.95 !important;
  color: white;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
  .h1tepart {
    font-size: 80px;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .h1tepart {
    font-size: 60px;
  }
  .divlay1 {
    width: 30%;
  }
}

@media all and (max-width: 600px) {
  .h1tepart {
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .divlay1 {
    width: 35%;
  }
  .divw96 {
    margin-bottom: 0;
  }
}
</style>
