<template>
  <v-container id="featu1">
    <v-layout wrap class="folde">

      <!-- <v-flex xs12 sm12 md6 class="lfc3">
        <h1 class="dorpgl96">{{ $t('feat1.title') }}</h1><br>
        <p class=" gfddf5">{{ $t('feat1.content1') }}</p>
        <p class=" gfddf5">{{ $t('feat1.content2') }}</p>
      </v-flex> -->

      <v-flex xs12 sm12 md6 class="lfc3">
        <h1 class="dorpgl96">{{ $t('feat1.title') }}</h1><br>
        <p class=" gfddf5">{{ $t('feat1.content1') }}</p>
        <p class=" gfddf5">{{ $t('feat1.content2') }}</p>
      </v-flex>

      <v-flex class="fplro33qw">
        <div class="fplro33"></div>
      </v-flex>         
    </v-layout>
  </v-container>
</template>
  
  
<script>
  import Vue from 'vue';
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default Vue.extend({
    name: 'Featu1',
  
    mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
  
    data: () => {
      return {
        data: {}
      }
    },
  })
</script>
  
  
<style>
.folde {
  margin-bottom: 100px;
  margin-top: 0px;
}

.dorpgl96 {
  font-size: 40px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  color: #f3976a;
  letter-spacing: 3px;
}
 
.lfc3 {
  margin: auto;
  width: 50%;
}

.fplro33 {
  margin: auto;
  /* box-shadow: 10px 10px 3px #666; */
  border-radius: 30px;
  background-image: url("~@/assets/otraotra.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* padding-top: 150px; */
  width: 390px;
  height: 350px;
}

.fplro33qw {
  margin: auto;
}

.gfddf5 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 300;
}
 
@media (max-width: 960px) {
  .dorpgl96 {
    text-align: center;
  }

  .gfddf5{
    text-align: center;
    font-size: 15px;
  }

  .fplro33qw {
    justify-content: center;
    margin-top: 50px;
  }
}
  
@media (max-width: 600px) {
  .dorpgl96 {
    font-size: 20px;
  }

  .fplro33 {
    width: 300px;
    height: 200px;
  }

  .gfddf5{
    font-size: 12px;
  }

  .folde {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .fplro33qw {
    margin-top: 25px;
  }
}

@media (max-width: 400px) {
  .fplro33 {
    width: 280px;
    height: 170px;
  }

  .folde {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .dorpgl96 {
    font-size: 17px;
  }
}

@media (max-width: 300px) {
  .fplro33 {
    width: 260px;
    height: 160px;
  }
}
</style>