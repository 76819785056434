<template>
  <div id="faq" class="sad542swww">
    <!-- <div>
      <div class="wave wave1"></div>
      <div class="wave wave2"></div>
      <div class="wave wave3"></div>
      <div class="wave wave4"></div>
    </div> -->
    <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1teadd" align="center" justify="center">{{ $t('faqs.title') }}</h1>

      <div class="flexfaq">
        <p class=" h1faq" data-aos="zoom-in" data-aos-duration="1000">{{ $t('faqs.content') }}</p><br>
      </div>
      
      <div class="asdvvv452">
        <v-expansion-panels multiple>
        <div class="dis5v">
          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques1') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ1') }}
            <!-- {{ $t('faqs.answ1-1') }}             -->
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques2') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ2') }}   
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques3') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ3') }} 
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques4') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ4') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>

<!------------------------------------------------------------------------------------------------------------------------->
          <div class="dis5vq">
          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques5') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ5') }}
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques6') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ6') }}  
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques7') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ7') }}
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="eeed523">
            <v-expansion-panel-header><h1 class="sdfsdf4">{{ $t('faqs.ques8') }}</h1></v-expansion-panel-header>
            <v-expansion-panel-content class="sdfsdf4p">
            {{ $t('faqs.answ8-1') }}<br>
            {{ $t('faqs.answ8-2') }}<br>
            {{ $t('faqs.answ8-3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
        </v-expansion-panels>
      </div>
  </div>
</template>


<script>
import Vue from 'vue';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

export default Vue.extend({
  name: 'Faq',

  mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },

  data: () => {
    return {
        presale: null,
    }
  },
})
</script>


<style>
/* .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
} */

.sad542swww {
  background: #012a4a;
  padding: 50px 100px 50px 100px;
}

.eeed523 {
  border-radius: 30px;
  padding: 5px;
}

.dis5v {
  width: 50%;
  padding-left: 120px;
  padding-right: 10px;
}

.dis5vq {
  width: 50%;
  padding-left: 10px;
  padding-right: 120px;
}

.sdfsdf4 {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.sdfsdf4p {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}

.eeed523 {
  margin-bottom: 20px;
}

.asdvvv452 {
  align-items: flex-end;
  display: flex;
  margin-top: 20px;
  /* margin-bottom: 100px; */
}

.h1teadd {
  margin-bottom: 40px;
  /* margin-top: 50px; */
  font-family: 'Anton', sans-serif;
  font-size: 50px;
  font-weight: 100;
  /* opacity: 0.9 !important; */
  text-align: center;
  color: white;
}

.imsdsad {
  max-height: 300px;
  background-size: 80%;
}

.before-enter {
  opacity: 0;
  transform: translateX(-150px);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: translateX(0px);
}

.flexfaq {
  margin: auto;
  padding-left: 8%;
  padding-right: 8%;
  text-align: center;
}

.h1faq {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.pbfaq {
  font-size: 18px;
  font-weight: bold;
  color: gray !important;
  text-align: center;
}

@media (max-width: 960px) {
  .h1faq {
    font-size: 15px;
  }
  .pbfaq {
    font-size: 16px;
  }

  .sad542swww {
    padding: 50px;
  }
}

@media all and (max-width: 600px) {
  .h1teadd {
    font-size: 40px;
  }

  .h1faq {
    font-size: 12px;
  }

  .asdvvv452 {
    align-items: center;
    display: inline;

  }
  .dis5v {
    width: 100%;
    padding: 20px;
  }

  .dis5vq {
    width: 100%;
    padding: 20px;
  }
}
</style>