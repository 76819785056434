<template>
  <v-container fluid id="footer">
    <div class="divpinf53s">
      <div class="peof5s">
        <div class="sad85s">
          <a class="h1ads" href="/"><v-img class="imglogo2fs" src="@/assets/2.png"></v-img></a>
        </div>
        <div>
          <p class="h2foo">Robiza Wine Token</p>
        </div>
      </div>

      <div class="peof5s">
        <div>
          <h2 class="h2foo">Links</h2>
        </div>

        <div>
          <a class="dodl" href="/en/mint"><h5 class="pfoot">NFT COLLECTION</h5></a>
          <a class="dodl" href="/#faq"><h5 class="pfoot">FAQ'S</h5></a>
          <a class="dodl" href="/en/terms"><h5 class="pfoot">{{ $t('contacts.btnterm') }}</h5></a>
          <a class="dodl" href="/en/privacypolicy"><h5 class="pfoot">{{ $t('contacts.btnpol') }}</h5></a>
        </div>
      </div>




          <div class="peof5s">
            <div>
                <h2 class="h2foo3">{{ $t('contacts.title') }}</h2>
            </div>

            <div class="clbuto">
              <v-btn class=" btnfootdq" icon color="white" @click="newtelegram" :small="$vuetify.breakpoint.mdAndDown">
                <span class="iconify" data-icon="cib:telegram-plane" data-width="18" data-height="18"></span>
              </v-btn>
              <v-btn class=" btnfoot" icon color="white" @click="newdiscord" :small="$vuetify.breakpoint.mdAndDown">
                <v-icon :small="$vuetify.breakpoint.mdAndDown">mdi-discord</v-icon>
              </v-btn>
              <v-btn class=" btnfoot" icon color="white" @click="newtwitter" :small="$vuetify.breakpoint.mdAndDown">
                <v-icon :small="$vuetify.breakpoint.mdAndDown">mdi-twitter</v-icon>
              </v-btn>
              <v-btn class=" btnfoot" icon color="white" @click="newinstagram" :small="$vuetify.breakpoint.mdAndDown">
                <v-icon :small="$vuetify.breakpoint.mdAndDown">mdi-instagram</v-icon>
              </v-btn>
              <!-- <v-btn class=" btnfoot" icon color="white" @click="newyoutube" :small="$vuetify.breakpoint.mdAndDown">
                <v-icon :small="$vuetify.breakpoint.mdAndDown">mdi-youtube</v-icon>
              </v-btn> -->
              <v-btn class=" btnfoot" icon color="white" @click="newmedium" :small="$vuetify.breakpoint.mdAndDown">
                <p class="btnfootp">M</p>
              </v-btn>
              <v-btn class=" btnfoot" icon color="white" href="mailto:robizawine@gmail.com" :small="$vuetify.breakpoint.mdAndDown">
                <v-icon :small="$vuetify.breakpoint.mdAndDown">mdi-email</v-icon>
              </v-btn>
          </div>
        </div>

    </div>

    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->

    <div class="laytit2ed" justify-center>
      <h1 class="pfooted2">{{ $t('contacts.risk') }}</h1>
      <p class="pfooted">{{ $t('contacts.riskcont') }}</p>
    </div>

    <div class="footer">
      <span class="spanfoot"></span>
      <div class="plds6">
        <span class="spanfoot">COPYRIGHT © 2022 Robiza Wine Token</span>
      </div>
    </div>
  </v-container>
</template>


<script>

export default {
  name: 'Contact',
  components: {
  },
  methods: {
    newtelegram: function() {
      window.open("https://t.me/+R1uhEw6pWXxlMjI0");
    },

    newinstagram: function() {
    window.open("https://www.instagram.com/robizawinenft/");
    },

    newdiscord: function() {
      window.open("https://discord.gg/mq7WyPGC");
    },

    newtwitter: function() {
    window.open("https://twitter.com/RWinetoken");
    },

    newmedium: function() {
    window.open("https://medium.com/@Robizawinetoken");
    },

    newyoutube: function() {
    window.open("https://www.youtube.com/channel/UCf4sW2a92jEk3nQBadxNRfA");
    },
  }
}
</script>


<style>
.sad85s {
  padding-top: 20px;
}

.clbuto {
  text-align: center;
}

.plds6 {
  margin-top: 10px;
}

.dodl {
  text-decoration: none;
}

.peof5s {
  width: fit-content;
  margin: auto;
  height: 170px;
  

}

.divpinf53s {
  padding-left: 50px;
  padding-right: 50px;
  column-count: 3;
  margin-bottom: 20px;
  margin-top: 50px;
}

.imglogo2fs {
  width: 80px;
  margin: auto;
}

.h1ads {
  text-decoration: none;
}

.divlink {
  margin-bottom: 20px;
  text-align: center;
}

.laytit2ed {
  padding-left: 10%;
  padding-right: 10%;
}

.soad45 {
  font-family: "Montserrat",Sans-serif;
}

.acos {
  color: black !important;
  text-decoration: none;
}

.spantermv {
  margin-right: 25px;
  font-family: "Montserrat",Sans-serif;
}

.divd5a {
  text-align: end;
}

.h2foo {
  font-size: 25px;
  font-weight: 100;
  font-family: 'Anton', sans-serif;
  font-weight: 100;
  opacity: 0.7 !important;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.h2foo3 {
  font-size: 25px;
  font-weight: 100;
  font-family: 'Anton', sans-serif;
  font-weight: 100;
  opacity: 0.7 !important;
  margin-top: 10px;
  padding-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.divprina {
  margin-top: 100px;
}

.iconify {
  margin-left: -3px;
}

.ddssddiv {
  margin-left: auto;
  margin-bottom: 15px; /* quitar al activar terminos y politicas*/
}

.div54sda {
  margin: auto;
}

.adasdas {
  position: absolute;
}

.spanfoota {
  color: white !important;
  font-size: 12px;
  margin-left: 10px;
  font-family: "Montserrat",Sans-serif;
}



.pfoot {
  color: white;
  font-family: "Montserrat",Sans-serif;
  opacity: 0.75 !important;
  margin-bottom: 8px;
}

.pfooted {
  color: white;
  font-family: "Montserrat",Sans-serif;
  text-align: justify;
  font-size: 10px;
}

.pfooted2 {
  color: white;
  font-family: "Montserrat",Sans-serif;
  font-size: 15px;
}

.imgbsc {
  width: 20px;
}

#footer {
  /* background: #fbbd97; */
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);
  color: white;


}

.btnfootp {
  font-family: 'Frank Ruhl Libre';
  font-size: 23px;
  margin: 15px;
}

.btnfoot {
  /* background: #f3976a; */
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);

  margin-left: 20px;
}

.btnfootdq {
  /* background: #f3976a; */
  background: linear-gradient(170deg, rgba(243,151,106,1) 17%, rgba(66,23,131,1) 90%);

}

.footer {
  margin-left: 100px;
  margin-right: 100px;
  border-style: solid;
  border-width: 2px 0px 0px 0px;
  border-color: #DDDDDD;
  color: white;
  font-size: 13px;
  text-align: center;
}

@media all and (min-width: 960px) and (max-width: 1264px) {
  .h2foo {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .h2foo3 {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .pfoot{
    font-size: 15px;
  }
  .spanfoot {
    font-size: 13px;
  }
  .spantermv {
    font-size: 13px;
    margin-right: 10px;
  }
  .soad45 {
    font-size: 13px;
  }
  .div54sda {
    justify-content: end;
  }

  .footer {
    margin-left: 70px;
    margin-right: 70px;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .h2foo {
    font-size: 25px;
  }
  .h2foo3 {
    font-size: 25px;
  }
  .spanfoot {
    font-size: 10px;
  }
  .spantermv {
    font-size: 11px;
    margin-right: 5px;
  }
  .soad45 {
    font-size: 11px;
  }
  .div54sda {
    margin-top: 30px;
    justify-content: center;
    width: 100%;
  }
  .ddssddiv {
    margin-left: 0;
  }
  .layfoot {
    padding-bottom: 10px;
  }
  .btnfootp {
    font-size: 20px;
  }
  .btnfoot {
    margin-left: 5px;
  }
  .footer {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media all and (max-width: 600px) {
  .clbuto {
    margin-bottom: 20px;
  }
  .divpinf53s {
    column-count: 1;
    padding-left: 0px;
    padding-right: 0px;
  }
  .h2foo {
    font-size: 25px;
  }
  .h2foo3 {
    font-size: 25px;
  }
  .pfoot{
    font-size: 12px;
  }
  .spanfoota {
    font-size: 10px;
  }
  .spanfoot {
    font-size: 10px;
  }
  .spantermv {
    font-size: 10px;
    margin-right: 10px;
    position: absolute;
    bottom: 30px;
  }
  .soad45 {
    font-size: 10px;
  }
  .layfoot {
    padding-bottom: 5px;
  }
  .footer {
    border-width: 1.4px 0px 0px 0px;
  }
  .laytit2 {
    margin-bottom: -10px;
  }
  .footer {
    margin-left: 30px;
    margin-right: 30px;
  }
  .btnfootp {
    font-size: 19px;
  }
  .pfooted {
    font-size: 8px;
  }

  .pfooted2 {
    font-size: 13px;
  }
}
</style>
