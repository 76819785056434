import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  enableIndexedDbPersistence,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDUi0zEE3518K8WoFXP0jYEdLRaOHdEIRg",
  authDomain: "robiza-wine-token.firebaseapp.com",
  projectId: "robiza-wine-token",
  storageBucket: "robiza-wine-token.appspot.com",
  messagingSenderId: "433956062833",
  appId: "1:433956062833:web:f6d4f348cbe7a80b77bdd0",
  measurementId: "G-SL869NMBW5"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Mapping
const firestore = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

// Auth Providers Languages
auth.useDeviceLanguage()

// Persistent data
enableIndexedDbPersistence(firestore)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

export const FbDb = firestore
export const FbAuth = auth
export const FbStorage = storage
