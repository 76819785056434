<template>
  <div id="roadmap">
    <div class="sasw2a">
      <v-container>
        <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1te">{{ $t('roadmap.title') }}</h1>
        <v-timeline class="timed5" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item small color="#f3976a">
            <h1 class="hdls" slot="opposite" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">Q4</h1>
            <span slot="opposite" class="spdls font-weight-bold" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">2022</span>
            <div class="carta-box1">
              <div class="carta1">
                <div class="cadrgcd" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                  <v-card-text class="card52d">
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-2') }}<br>

                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4') }} -->
                  </v-card-text>
                </div>

                <div class="cadrgcd detras1">
                  <v-card-text class="card52d">
                    <v-icon color="green" small>mdi-check</v-icon><del>{{ $t('roadmap.content1') }}</del><br>
                    <v-icon color="green" small>mdi-check</v-icon><del>{{ $t('roadmap.content2') }}</del><br>
                    <v-icon color="green" small>mdi-check</v-icon><del>{{ $t('roadmap.content3') }}</del><br>
                    <v-icon color="green" small>mdi-check</v-icon><del>{{ $t('roadmap.content1-2') }}</del><br>

                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4') }} -->
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small color="#f3976a" >
            <h1 class="hdls" slot="opposite" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">Q1</h1>
            <span slot="opposite" class="spdls font-weight-bold" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">2023</span>
            <div class="carta-box1">
              <div class="carta1">
                <div class="cardroad" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                  <v-card-text class="card52d">
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-2') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-2') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-2') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-2') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-4') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-3') }} -->


                  </v-card-text>
                </div>

                <div class="cardroad detras1">
                  <v-card-text class="card52d">
                    <!-- <v-icon color="green" small>mdi-check</v-icon><del>{{ $t('roadmap.content1-2') }}</del><br> -->
                    <v-icon color="green" small>mdi-check</v-icon>{{ $t('roadmap.content2-2') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-2') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-2') }}<br> 
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-4') }} <br>    
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-3') }}
          

                  </v-card-text>
                </div>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item fill-dot fab small color="#f3976a">
            <h1 class="hdls" slot="opposite" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">Q2</h1>
            <span slot="opposite" class="spdls font-weight-bold" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">2023</span>
            <div class="carta-box1">
              <div class="carta1">
                <div class="cadrgcd" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                  <v-card-text class="card52d">
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-3') }}<br>
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-3') }}<br>
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-3') }}<br>
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content7-3') }}<br>

                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-3') }}<br>
                  
                  <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content5-3') }}<br> -->
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content6-3') }}
                  </v-card-text>
                </div>

                <div class="cadrgcd detras1">
                  <v-card-text class="card52d">
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-3') }}<br>
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-3') }}<br>                  
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-3') }}<br>
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content7-3') }}<br>

                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-3') }}<br>
                  <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content5-3') }}<br> -->
                  <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content6-3') }}
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small color="#f3976a" fill-dot fab>
            <h1 class="hdls" slot="opposite" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">Q3</h1>
            <span slot="opposite" class="spdls font-weight-bold" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">2023</span>
            <div class="carta-box1">
              <div class="carta1">
                <div class="cardroad" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                  <v-card-text class="card52d">
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-4') }}<br> -->
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-4') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content5-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content8-3') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content6-4') }}<br> -->
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content7-4') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content8-4') }}
                  </v-card-text>
                </div>

                <div class="cardroad detras1">
                  <v-card-text class="card52d">
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-4') }}<br> -->
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-4') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-4') }}<br>                  
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content5-4') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content8-3') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content6-4') }}<br> -->
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content7-4') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content8-4') }}
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small color="#f3976a" fill-dot fab>
            <h1 class="hdls" slot="opposite" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">Q4</h1>
            <span slot="opposite" class="spdls font-weight-bold" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">2023</span>
            <div class="carta-box1">
              <div class="carta1">
                <div class="cadrgcd" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                  <v-card-text class="card52d">
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-5') }}<br>
                  <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-5') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-5') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-5') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-2') }}
                  </v-card-text>
                </div>

                <div class="cadrgcd detras1">
                  <v-card-text class="card52d">
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content1-5') }}<br>
                    <!-- <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content2-5') }}<br> -->
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-5') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content4-5') }}<br>
                    <v-icon color="#f3976a" small>mdi-circle-medium</v-icon>{{ $t('roadmap.content3-2') }}
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
        <div data-aos="zoom-in" data-aos-duration="1000" class="divfin">
          <h1>{{ $t('roadmap.more') }}</h1>
        </div>  
      </v-container>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Roadmap',
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap');

.divfin {
  text-align: center;
  padding-top: 50px;
  font-size: 18px;
  font-family: 'Anton', sans-serif;
  font-weight: 100;
  color: rgb(77, 77, 77);
  opacity: 0.75;
}

.timed5 {
  max-width: 77%;
  margin: auto;
}

.imgclssa {
  opacity: 0.3;
  position: absolute;
  width: 100%;
}

.cadrgcd.detras1 {
  transform: rotateX(180deg);
  position: relative;
}

.carta-box1 {
  width: 100%;
  height: 100%;
  position: relative;
  perspective: 1000px;
}

.carta-box1:hover .carta1 {
  transform: rotateX(180deg);
}

.carta1 {
  transform-style: preserve-3d;
  transition: all 0.8s linear;
}

.sasw2a {
  padding-bottom: 50px;
  padding-top: 100px;
  background-image: url(../assets/mainroad.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cadrgcd {
  position: absolute;
  backface-visibility: hidden;
  border: solid;
  border-color: #b56b16;
  border-radius: 30px;
  background: #ffffffa6 !important;
  border-width: 1px !important;
  width: 100%;
  font-family: "Montserrat",Sans-serif;
  height: auto;
}

.spdls {
  font-size: 18px;
  font-family: 'Anton', sans-serif;
  font-weight: 100;
  color: rgb(77, 77, 77);
  opacity: 0.9;
}

.hdls {
  font-size: 40px;
  font-family: 'Anton', sans-serif;
  font-weight: 100;
  color: rgb(77, 77, 77);
  opacity: 0.9;
}

.card52d {
  font-size: 13px !important;
  color: #000;
  font-weight: 500 !important;
}

.cardroad {
  position: absolute;
  backface-visibility: hidden;
  border: solid;
  border-color: #b56b16;
  border-radius: 30px;
  background: #ffffffa6 !important;
  border-width: 1px !important;
  width: 100%;
  height: auto;
  font-family: "Montserrat",Sans-serif;
}

.cardroad.detras1 {
  transform: rotateX(180deg);
  position: relative; 
}

.h1te {
  margin-bottom: 40px;
  font-family: 'Anton', sans-serif;
  font-size: 100px;
  font-weight: 100;
  opacity: 0.9 !important;
  z-index: 1;
  position: relative;
}

.roadtext {
  color: #000000;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
  .h1te {
    font-size: 80px;
    text-align: center;
  }
  .timed5 {
    max-width: 100%;
  }
  .sasw2a {
    padding-bottom: 50px;
    padding-top: 50px;
    background-position: center center;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .h1te {
    font-size: 60px;
    text-align: center;
  }
  .timed5 {
    max-width: 50%;
  }
  .card52d {
    font-size: 12px !important;
  }
  .sasw2a {
    padding-bottom: 50px;
    padding-top: 50px;
    background-position: center center;
  }
}

@media all and (max-width: 600px) {
  .sasw2a {
    padding-bottom: 50px;
    padding-top: 50px;
    background-position: center center;
  }
  .h1te {
    font-size: 40px;
    text-align: center;
  }
  .hdls {
    font-size: 20px;
  }
  .card52d {
    font-size: 9px !important;
  }
  .timed5 {
    margin: 0%;
    max-width: 90%;
  }
  .divfin {
    font-size: 15px;
  }
}
</style>
