<template>
  <div class="divw96">
    <h1 data-aos="zoom-in" data-aos-duration="1000" class="h1tepart1qa">{{ $t('club.title') }}</h1>
    <div class="div1sa2sd">
      <div class="do3">
        <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="asdxx">{{ $t('club.content1') }}</p>
        <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" class="kkk">{{ $t('club.content2') }}</p>
        <div class="pbitd">
          <v-btn data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" color="#f3976a" rounded outlined dark href="/en/mint">{{ $t('club.btnclub') }}</v-btn>
        </div>
      </div>

      <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000" class="do32">
          <v-img class="dikf" src="@/assets/otraotra.png"></v-img>
      </div>
    </div>
  </div>
</template>


<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'club',
  mounted() {
      
    AOS.init({
      disable:true

      // disable: function () {
      //   var maxWidth = 800;
      //   return window.innerWidth < maxWidth;
      // }
    });
  },
}
</script>


<style>

.div1sa2sd {
  align-items: flex-end;
  display: flex;
  padding-left: 100px;
  box-sizing: border-box;
  /* padding-bottom: 50px; */
}

.asdxx {
  font-size: 30px;
  color: #f3976a;
  text-align: left;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
}

/* .butwqqw {
  background: #f3976a;
} */

.do32 {
  width: 40%;
  margin: auto;
}

.do3 {
  width: 60%;
  margin: auto;
  padding-right: 60px;
}

.dikf {
  width: 90%;
  /* box-shadow: -10px 10px 3px #666; */
  border-radius: 30px;
}

.divw96 {
  background: #001233 !important;
  padding-top: 100px;
  padding-bottom: 100px;
}

.h1tepart1qa {
  text-align: end;
  font-size: 90px;
  font-weight: 100;
  opacity: 0.95 !important;
  padding-bottom: 20px;
  margin-right: 50px;
  font-family: 'Anton', sans-serif;
  color: white;
}

@media all and (min-width: 960px) and (max-width: 1264px) {
  .asdxx {
    font-size: 25px;
  }

  .do3 {
    width: 100%;
    padding-right: 40px;
  }

  .h1tepart1qa {
    font-size: 80px;
    padding-bottom: 0px;
  }

  .div1sa2sd {
    padding-bottom: 60px;
  }

}

@media all and (min-width: 600px) and (max-width: 960px) {
  .pbitd {
    text-align: center;
  }

  .do32 {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 70%;
  }

  .do3 {
    width: 80%;
    padding-right: 0px;
  }

  .asdxx {
    font-size: 20px;
  }

  .h1tepart1qa {
    font-size: 60px;
    padding-bottom: 0px;
  }

  .div1sa2sd {
    align-items: center;
    display: inline;
  } 
}

@media all and (max-width: 600px) {
  .pbitd {
    text-align: center;
  }           

  .do32 {
    padding-top: 40px;
    padding-bottom: 50px;
    width: 70%;
  }

  .do3 {
    width: 90%;
    padding-right: 0px;
  }

  .asdxx {
    font-size: 17px;
  }

  .h1tepart1qa {
    font-size: 40px;
    padding-bottom: 0px;
    margin-right: 20px;
  }

  .div1sa2sd {
    align-items: center;
    display: inline;
  } 
}
</style>
